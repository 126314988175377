import React, { useEffect, useState } from 'react';
import { Upload, Modal, message, Row } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { CloudUploadOutlined } from '@ant-design/icons/lib';
import { checkImage } from 'common/helpers/loader.helper';
import { EFileStatus, IImageModel } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { useAppSelector } from 'app/store/store.hooks';

interface IComponentProps {
  disabled?: boolean;
  onChange?: (imageId: string | null) => void;
  value?: string;
}

export const ImageUpload: React.FC<IComponentProps> = ({ disabled, onChange, value }) => {
  const { authModel } = useAppSelector((state) => state.auth);
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const [imageId, setImageId] = useState<string>('');
  const [fileList, setFileList] = useState<UploadFile<IImageModel>[]>([]);

  useEffect(() => {
    initValue(value);
  }, [value]);

  const handleCancel = () => setIsPreviewVisible(false);

  const handlePreview = (file: UploadFile<IImageModel>) => {
    const imageId = file.response?.id || value;

    if (imageId) {
      setImageId(imageId);
      setIsPreviewVisible(true);
    }
  };

  const initValue = (value?: string) => {
    const fileList: UploadFile<IImageModel>[] = value
      ? [
          {
            uid: '1',
            name: 'image',
            status: 'done',
            size: 1,
            type: '',
            url: `${window.location.origin}/api/images/${value}`,
          },
        ]
      : [];

    setFileList(fileList);
  };

  const handleChange = (info: UploadChangeParam<UploadFile<IImageModel>>) => {
    const { file } = info;
    const { status, response } = file;

    if (file.status === EFileStatus.Removed && onChange) {
      setFileList([]);
      onChange(null);

      return;
    }

    if (status === EFileStatus.Done) {
      const imageId = response?.id;

      setFileList([file]);

      if (onChange) {
        onChange(imageId || null);
      }

      return;
    }
    if (status === EFileStatus.Error && file.error.status === EErrorStatus.Unauthorized) {
      message.warning('Something went wrong, reload page and try again');
    }

    setFileList([file]);
  };

  const token = authModel?.access.token;

  const uploadButton = (
    <Row justify="center" align="middle">
      <CloudUploadOutlined />
      <div className="ant-upload-text width-full">Upload</div>
    </Row>
  );
  return (
    <>
      <Upload
        action="/api/images"
        headers={{ authorization: `Bearer ${token}` }}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        name="image"
        beforeUpload={checkImage}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon: false,
          showRemoveIcon: true,
        }}
        disabled={disabled}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal open={isPreviewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" className="width-full p-6" src={`/api/images/${imageId}`} />
      </Modal>
    </>
  );
};
