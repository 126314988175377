import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Row, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesCommon } from 'common/models/routesModel';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import menuBurger from 'app/assets/icons/menuBurger.svg';
import menuClose from 'app/assets/icons/menuClose.svg';
import { useAppSelector } from 'app/store/store.hooks';
import { EUserRole, EUserRoleText } from 'entities/User/User.models';

type AllProps = RouteComponentProps;

export const HeaderMenuWrapperMob: React.FC<AllProps> = () => {
  const { authUser } = useAppSelector((state) => state.auth);
  const logoRole = authUser?.role === EUserRole.SalesOps ? EUserRoleText.SalesOps : EUserRoleText.Franchisee;
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const showDrawer = () => {
    setIsMenuVisible(true);
  };

  const closeDrawer = () => {
    setIsMenuVisible(false);
  };

  return (
    <>
      <Row gutter={10} align="middle" className="flex-noWrap">
        <Button className="btn-opacity" onClick={showDrawer}>
          <img src={menuBurger} alt="open menu" />
        </Button>

        <Link to={ERoutesCommon.Root}>
          <span className="header-franchisee__title header-franchisee__title_with-p">MB</span>
          <span className="header-franchisee__title_role">— {logoRole}</span>
        </Link>
      </Row>

      <Drawer
        placement="top"
        closable={false}
        onClose={closeDrawer}
        open={isMenuVisible}
        key="top"
        className="header-franchisee-menu__drawer"
        height="auto"
      >
        <Row gutter={10} align="middle">
          <Button className="btn-opacity btn-opacity_sm" onClick={closeDrawer}>
            <img src={menuClose} alt="close menu" />
          </Button>

          <Link to={ERoutesCommon.Root} className="flex__item_1 mt-1">
            <span className="header-franchisee__title header-franchisee__title_with-p">MB</span>
            <span className="header-franchisee__title_role">— {logoRole}</span>
          </Link>
        </Row>

        <HeaderMenu mobile close={closeDrawer} />
      </Drawer>
    </>
  );
};
