import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import {
  EAssetSuccessMessage,
  IAssetCollection,
  IAssetCollectionFilter,
  IAssetCreateData,
  IAssetModel,
  IAssetParamsModel,
  IAssetUpdateData,
} from 'entities/Asset/Asset.models';
import { assetTransport } from 'entities/Asset/Asset.transport';

export interface IState {
  assetModelLoading: boolean;
  assetModel: IAssetModel | null;
  assetModelError: IError | null;
  assetModelParams: IAssetParamsModel | null;
  assetCollectionLoading: boolean;
  assetCollection: IAssetCollection | null;
  assetCollectionError: IError | null;
  assetCollectionParams: IAssetCollectionFilter | null;
}

const initialState: IState = {
  assetModelLoading: false,
  assetModel: null,
  assetModelError: null,
  assetModelParams: null,
  assetCollectionLoading: false,
  assetCollection: null,
  assetCollectionError: null,
  assetCollectionParams: null,
};

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAssetModelLoading(state, action: PayloadAction<boolean>) {
      state.assetModelLoading = action.payload;
    },
    setAssetModel(state, action: PayloadAction<IAssetModel | null>) {
      state.assetModel = action.payload;
    },
    setAssetModelError(state, action: PayloadAction<IError | null>) {
      state.assetModelError = action.payload;
    },
    setAssetModelParams(state, action: PayloadAction<IAssetParamsModel | null>) {
      state.assetModelParams = action.payload;
    },
    setAssetCollectionLoading(state, action: PayloadAction<boolean>) {
      state.assetCollectionLoading = action.payload;
    },
    setAssetCollection(state, action: PayloadAction<IAssetCollection | null>) {
      state.assetCollection = action.payload;
    },
    setAssetCollectionError(state, action: PayloadAction<IError | null>) {
      state.assetCollectionError = action.payload;
    },
    setAssetCollectionParams(state, action: PayloadAction<IAssetCollectionFilter | null>) {
      state.assetCollectionParams = action.payload;
    },
  },
});

export const {
  setAssetModelLoading,
  setAssetModel,
  setAssetModelError,
  setAssetModelParams,
  setAssetCollectionLoading,
  setAssetCollection,
  setAssetCollectionError,
  setAssetCollectionParams,
} = assetSlice.actions;
export default assetSlice.reducer;

export const getAssetModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAssetModelLoading(true));

    try {
      const assetModel = await assetTransport.get(id);

      dispatch(setAssetModel(assetModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAssetModelError(_error));
    } finally {
      dispatch(setAssetModelLoading(false));
    }
  }

  return thunk;
};

export const addAssetModel = (params: IAssetCreateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAssetModelLoading(true));

    try {
      const assetModel = await assetTransport.add(params);

      dispatch(setAssetModel(assetModel));

      message.success(EAssetSuccessMessage.Add);
      history.push(`${ERoutesPrivate.Menu}?tab=assets`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAssetModelError(_error));
    } finally {
      dispatch(setAssetModelLoading(false));
    }
  }

  return thunk;
};

export const updateAssetModel = (params: IAssetUpdateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAssetModelLoading(true));

    try {
      const assetModel = await assetTransport.update(params);

      dispatch(setAssetModel(assetModel));

      message.success(EAssetSuccessMessage.Edit);
      history.push(`${ERoutesPrivate.Menu}?tab=assets`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAssetModelError(_error));
    } finally {
      dispatch(setAssetModelLoading(false));
    }
  }

  return thunk;
};

export const deleteAssetModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAssetModelLoading(true));

    try {
      await assetTransport.delete(id);

      dispatch(setAssetModel(null));

      message.success(EAssetSuccessMessage.Delete);
      history.push(`${ERoutesPrivate.Menu}?tab=assets`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAssetModelError(_error));
    } finally {
      dispatch(setAssetModelLoading(false));
    }
  }

  return thunk;
};

export const getAssetCollection = (filter?: IAssetCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAssetCollectionLoading(true));

    try {
      const assetCollection = await assetTransport.getCollection(filter);

      dispatch(setAssetCollection(assetCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAssetCollectionError(_error));
    } finally {
      dispatch(setAssetCollectionLoading(false));
    }
  }

  return thunk;
};
