import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import {
  EStoreSuccessMessage,
  IStoreCollection,
  IStoreCollectionFilter,
  IStoreModel,
  IStoreParams,
} from 'entities/Store/Store.models';
import { storeTransport } from 'entities/Store/Store.transport';

export interface IState {
  storeModelLoading: boolean;
  storeModel: IStoreModel | null;
  storeModelError: IError | null;
  storeModelParams: IStoreParams | null;
  storeCollectionLoading: boolean;
  storeCollection: IStoreCollection | null;
  storeCollectionError: IError | null;
  storeCollectionParams: IStoreCollectionFilter | null;
}

const initialState: IState = {
  storeModelLoading: false,
  storeModel: null,
  storeModelError: null,
  storeModelParams: null,
  storeCollectionLoading: false,
  storeCollection: null,
  storeCollectionError: null,
  storeCollectionParams: null,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreModelLoading(state, action: PayloadAction<boolean>) {
      state.storeModelLoading = action.payload;
    },
    setStoreModel(state, action: PayloadAction<IStoreModel | null>) {
      state.storeModel = action.payload;
    },
    setStoreModelError(state, action: PayloadAction<IError | null>) {
      state.storeModelError = action.payload;
    },
    setStoreModelParams(state, action: PayloadAction<IStoreParams | null>) {
      state.storeModelParams = action.payload;
    },
    setStoreCollectionLoading(state, action: PayloadAction<boolean>) {
      state.storeCollectionLoading = action.payload;
    },
    setStoreCollection(state, action: PayloadAction<IStoreCollection | null>) {
      state.storeCollection = action.payload;
    },
    setStoreCollectionError(state, action: PayloadAction<IError | null>) {
      state.storeCollectionError = action.payload;
    },
    setStoreCollectionParams(state, action: PayloadAction<IStoreCollectionFilter | null>) {
      state.storeCollectionParams = action.payload;
    },
  },
});

export const {
  setStoreModelLoading,
  setStoreModel,
  setStoreModelError,
  setStoreModelParams,
  setStoreCollectionLoading,
  setStoreCollection,
  setStoreCollectionError,
  setStoreCollectionParams,
} = storeSlice.actions;
export default storeSlice.reducer;

export const getStoreModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModelLoading(true));

    try {
      const storeModel = await storeTransport.get(id);

      dispatch(setStoreModel(storeModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const getByShortIdStoreModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModelLoading(true));

    try {
      const storeModel = await storeTransport.getByShortId(id);

      dispatch(setStoreModel(storeModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const addStoreModel = (params: IStoreParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModelLoading(true));
    dispatch(setStoreModelParams(params));

    try {
      const storeModel = await storeTransport.add(params);

      dispatch(setStoreModel(storeModel));

      message.success(EStoreSuccessMessage.Create);
      dispatch(setStoreModelParams(null));

      history.push(ERoutesPrivate.Stores);
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const updateStoreModel = (params: IStoreParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModelLoading(true));
    dispatch(setStoreModelParams(params));

    try {
      const storeModel = await storeTransport.update(params);

      dispatch(setStoreModel(storeModel));

      message.success(EStoreSuccessMessage.Edit);
      dispatch(setStoreModelParams(null));

      history.push(ERoutesPrivate.Stores);
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const deleteStoreModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModelLoading(true));

    try {
      await storeTransport.delete(id);

      dispatch(setStoreModel(null));

      message.success(EStoreSuccessMessage.Delete);
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const getStoreCollection = (filter?: IStoreCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreCollectionLoading(true));

    try {
      const storeCollection = await storeTransport.getCollection(filter);

      dispatch(setStoreCollection(storeCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreCollectionError(_error));
    } finally {
      dispatch(setStoreCollectionLoading(false));
    }
  }

  return thunk;
};
