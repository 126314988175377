import React from 'react';
import { Button, Col, Row } from 'antd';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { useAppDispatch } from 'app/store/store.hooks';
import { getStatisticCSV } from 'app/store/reducers/statistic.reducer';

export const StatisticFranchisorPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(getStatisticCSV());
  };

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
      <Row className="mb-10 width-full" justify="space-between" align="middle">
        <Col span={18}>
          <Row justify="space-between" align="middle">
            <Col span={8}>
              <Button block type="primary" title="Download CSV" onClick={handleSubmit}>
                Download CSV
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonLayout>
  );
};
