import React from 'react';
import { Col, Row, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash.debounce';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';
import { IMultipleSelectorListItem, MultipleSelectorList } from 'common/components/List/StoreMultipleSelectorList';
import { EUserRole, IUserModel, IFranchiseeData } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

interface IComponentProps {
  value?: string[];
  defaultValue?: string[];
  onChange?: (items: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

interface IComponentState {
  users: IUserModel[];
  value?: IUserModel;
  items: IFranchiseeData[];
}

export class UserMultipleSelector extends React.PureComponent<AllProps> {
  state: IComponentState = {
    users: [],
    value: undefined,
    items: [],
  };

  readonly searchDebounced: any;

  static getDerivedStateFromProps(props: AllProps, state: IComponentState) {
    const { items, users } = state;
    const { value } = props;

    if (!value?.length && items.length) {
      return { items: [] };
    }

    if (!items.length && value && value.length && users) {
      return { items: value };
    }

    return null;
  }

  constructor(props: AllProps) {
    super(props);
    this.searchDebounced = debounce(this.handleSearch, 200);
  }

  async componentDidMount() {
    /* This limit specified to get a full list of fr-ee to avoid to make request for list of selected fr-ee,
    because we get data about fr-ees as id-string array (w/o firstname, lastname etc.)
    Think about: we need to get a list of fr-ee assisgned to a specific sales ops as minimum.
    In addition, we need to get a list of fr-ee that can be selected 
    Tech debt task - https://axmit.myjetbrains.com/youtrack/agiles/99-68/current?issue=MB-1998 */
    const { data } = await userTransport.getCollection({ roles: [EUserRole.Franchisee] });
    this.setState({ users: data });
  }

  handleSearch = async (value: string) => {
    const { data } = await userTransport.getCollection({ roles: [EUserRole.Franchisee], search: value });
    this.setState({ users: data });
  };

  deleteItem = (item: IMultipleSelectorListItem) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const filteredItems = items.filter((el) => el.id !== item.id);
    this.setState({ items: filteredItems });

    if (onChange) {
      onChange(filteredItems.map((it) => it.id));
    }
  };

  handleChange = async (value: string) => {
    const { onChange } = this.props;
    const { items, users } = this.state;
    const item = users.find((el) => el.id === value);
    const itemExist = items.find((el) => el.id === value);

    if (item && !itemExist) {
      items.push({ id: item.id, firstName: item.firstName || '', lastName: item.lastName || '' });
    }

    if (onChange) {
      onChange(items.map((it) => it.id));
    }

    this.setState({ value: undefined, items });
  };

  render() {
    const { value, items, users } = this.state;
    const { disabled } = this.props;
    const options = users.map((option) => (
      <Select.Option key={option.id} value={option.id}>
        {option.firstName} {option.lastName}
      </Select.Option>
    ));
    const label = items.length ? (value ? `${value.firstName} ${value.lastName}` : null) : undefined;

    return (
      <Col>
        <Row>
          <Select
            className="width-full"
            showSearch
            disabled={disabled}
            value={label as any}
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={this.searchDebounced}
            onChange={this.handleChange}
            notFoundContent={<ItemsNotFound />}
            placeholder="Select franchisee"
          >
            {options}
          </Select>
        </Row>

        {!!items.length && (
          <Row className="mt-5">
            <MultipleSelectorList
              items={items.map((el) => ({ id: el.id, title: `${el.firstName} ${el.lastName}` }))}
              onClick={this.deleteItem}
            />
          </Row>
        )}
      </Col>
    );
  }
}
