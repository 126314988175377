// Custom object for DatePicker
// reason: antd v5 with MomentJS incompatibility - https://ant.design/docs/react/migration-v5#technology-adjustment
// solution - https://ant.design/docs/react/use-custom-date-library#custom-component

import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const CustomDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

export default CustomDatePicker;
