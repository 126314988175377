import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPromoStatusText } from 'entities/Promo/Promo.models';

interface IComponentProps {
  onChange?: (value?: EPromoStatusText) => void;
  value?: EPromoStatusText;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PromoStausSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPromoStatusText) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, placeholder } = this.props;
    return (
      <Select
        allowClear
        className="width-full"
        placeholder={placeholder}
        value={value}
        defaultActiveFirstOption={false}
        onChange={(value: EPromoStatusText) => this.handleChange(value)}
      >
        <Select.Option key={EPromoStatusText.Enable} value={EPromoStatusText.Enable} label={EPromoStatusText.Enable}>
          {EPromoStatusText.Enable}
        </Select.Option>
        <Select.Option key={EPromoStatusText.Disable} value={EPromoStatusText.Disable} label={EPromoStatusText.Disable}>
          {EPromoStatusText.Disable}
        </Select.Option>
      </Select>
    );
  }
}
