import React from 'react';
import { Row } from 'antd';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { StatisticStoreList } from 'entities/Statistic/components/StatisticStoreList';

const StoreListFranchiseePageComponent: React.FC<{}> = () => {
  return (
    <FranchiseeCenterLayout>
      <Row justify="center" align="middle">
        <span className="franchisee__text_title" title="Stores">
          Stores
        </span>
      </Row>
      <StatisticStoreList />
    </FranchiseeCenterLayout>
  );
};

export const StoreListFranchiseePage = StoreListFranchiseePageComponent;
