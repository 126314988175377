// import React from 'react';
// import { Table } from 'antd';
// import { StoreBranch } from '@axmit/redux-communications';
// import { ColumnsType } from 'antd/lib/table';
// import { IBaseFilterModel } from 'common/models/requestModels';
// import { queryToObject } from 'common/helpers/filters.helper';

// interface IComponentProps<
//   Model,
//   Collection extends { data: Model[]; meta: { count?: number } },
//   IConfig extends ColumnsType<any>,
//   Filter extends any = undefined
// > {
//   config: IConfig;
//   filter?: Filter;
// }
// interface IComponentState {
//   pageIndex?: number;
// }
// const firstPage = 1;

// abstract class TableCommonComponent<
//   Collection extends { data: Model[]; meta: { count?: number } },
//   Model,
//   IConfig extends ColumnsType<any>,
//   IProps,
//   Filter extends any = undefined,
//   Params = any
// > extends React.PureComponent<IComponentProps<Model, Collection, IConfig, Filter> & IProps, IComponentState> {
//   constructor(props: IComponentProps<Model, Collection, IConfig, Filter> & IProps) {
//     super(props);
//     const queryObject: IComponentState = queryToObject({ pageIndex: 1 });
//     this.state = { pageIndex: queryObject.pageIndex };
//   }
//   componentDidMount(): void {
//     const { pageIndex } = this.state;
//     this.loadData(pageIndex || firstPage, 10);
//   }
//   componentWillUnmount(): void {
//     this.clearCollection();
//   }

//   componentDidUpdate(prevProps: Readonly<IComponentProps<Model, Collection, IConfig, Filter> & IProps>) {
//     if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
//       this.loadData(firstPage, 10);
//     }
//   }
//   render() {
//     const { config } = this.props;
//     const { pageIndex } = this.state;
//     const collection = this.getCollection();
//     const { data: collectionData, loading } = collection;
//     const items = collectionData?.data || [];
//     const count = collectionData?.meta.count || 0;

//     return (
//       <Table
//         columns={config}
//         dataSource={items}
//         rowKey="id"
//         bordered
//         size="middle"
//         onRow={record => {
//           return {
//             onClick: () => this.onRowClick(record)
//           };
//         }}
//         pagination={{
//           onChange: this.loadData,
//           total: count,
//           position: ['bottomCenter'],
//           defaultCurrent: pageIndex,
//           current: pageIndex
//         }}
//         loading={loading}
//       />
//     );
//   }

//   loadData = (page: number, pageSize?: number) => {
//     const pageNumber = page >= 1 ? page : 1;
//     const offset = pageSize ? pageSize * (pageNumber - 1) : 0;
//     this.setState({ pageIndex: pageNumber }, () => this.addQuery(pageNumber));
//     this.loadCollection({ limit: pageSize, offset });
//   };

//   clearCollection: () => void = () => {};
//   onRowClick: (item: Model) => void = () => {};
//   addQuery: (pageIndex: number) => void = () => {};
//   abstract getCollection: () => StoreBranch<Collection, Params>;
//   abstract loadCollection: (params: IBaseFilterModel) => void;
// }

// export const TableCommon = TableCommonComponent;

import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IBaseFilterModel } from 'common/models/requestModels';

interface ITableCommonProps<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model,
  IConfig extends ColumnsType<any>,
  Filter extends { pageIndex?: number }
> {
  config: IConfig;
  filter?: Filter;
  collection: Collection | null;
  loadCollection: (params: IBaseFilterModel) => void;
  clearCollection: () => void;
  onRowClick?: (item: Model) => void;
  addQuery?: (pageIndex: number) => void;
  loading: boolean;
}

export function TableCommon<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model,
  IConfig extends ColumnsType<any>,
  Filter extends { pageIndex?: number }
>({
  config,
  filter,
  collection,
  loadCollection,
  clearCollection,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addQuery = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowClick = () => {},
  loading,
}: ITableCommonProps<Collection, Model, IConfig, Filter>) {
  const [updatedFilter, setUpdatedFilter] = useState<Filter | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const items = collection?.data || [];
  const count = collection?.meta.count || 0;

  const loadData = (page: number, pageSize: number = 10) => {
    const pageNumber = page >= 1 ? page : 1;
    const offset = pageSize * (pageNumber - 1);

    setPageIndex(pageNumber);
    addQuery(pageNumber);
    loadCollection({ limit: pageSize, offset });
  };

  useEffect(() => {
    return () => {
      clearCollection();
    };
  }, []);

  useEffect(() => {
    if (!filter) {
      loadData(1, 10);

      return;
    }

    if (JSON.stringify(updatedFilter) !== JSON.stringify(filter)) {
      setUpdatedFilter(filter);
      loadData(filter?.pageIndex || 1, 10);
    }
  }, [filter]);

  return (
    <Table
      columns={config}
      dataSource={items}
      rowKey="id"
      bordered
      size="middle"
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
      pagination={{
        onChange: loadData,
        total: count,
        position: ['bottomCenter'],
        defaultCurrent: pageIndex,
        current: pageIndex,
      }}
      loading={loading}
    />
  );
}

export default TableCommon;
