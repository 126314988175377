import { createBrowserHistory } from 'history';
import { combineReducers, configureStore, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { IError } from '@axmit/error-helper';
import { errorWatcher } from 'common/utils/errorWatcher';
import authReducer from 'app/store/reducers/auth.reducer';
import userReducer from 'app/store/reducers/user.reducer';
import assetReducer from 'app/store/reducers/asset.reducer';
import categoryReducer from 'app/store/reducers/category.reducer';
import customerReducer from 'app/store/reducers/customer.reducer';
import menuItemReducer from 'app/store/reducers/menuItem.reducer';
import orderReducer from 'app/store/reducers/order.reducer';
import promoReducer from 'app/store/reducers/promo.reducer';
import qrReducer from 'app/store/reducers/qr.reducer';
import ratingReducer from 'app/store/reducers/rating.reducer';
import statisticReducer from 'app/store/reducers/statistic.reducer';
import storeReducer from 'app/store/reducers/store.reducer';

const { createReduxHistory, routerReducer, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const appReducers = combineReducers({
  router: routerReducer,
  auth: authReducer,
  user: userReducer,
  asset: assetReducer,
  category: categoryReducer,
  customer: customerReducer,
  menuItem: menuItemReducer,
  order: orderReducer,
  promo: promoReducer,
  qr: qrReducer,
  rating: ratingReducer,
  statistic: statisticReducer,
  store: storeReducer,
});

export const errorHanler: Middleware = () => (next) => (action) => {
  if (typeof action === 'object' && (action as PayloadAction).type.includes('Error')) {
    const error = (action as PayloadAction).payload as unknown as IError;

    errorWatcher(error);
  }

  return next(action);
};

export const store = configureStore({
  reducer: appReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(routerMiddleware).prepend(errorHanler),
});

export const history = createReduxHistory(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
