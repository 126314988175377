// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import {
//   IMenuItemCollection,
//   IMenuItemCollectionFilter,
//   IMenuItemCreateData,
//   IMenuItemModel,
//   IMenuItemsNotOnMenuParams,
//   IMenuItemUpdateData,
// } from 'entities/MenuItem/MenuItem.models';

// const basePath = '/menu-items';

// class MenuItemTransport extends BaseHttpTransport<
//   AxiosStatic,
//   IMenuItemModel,
//   IMenuItemCollection,
//   IMenuItemCreateData,
//   IMenuItemUpdateData,
//   IMenuItemCollectionFilter
// > {
// public deleteSoft = (id: string): Promise<void> => axios.delete(`${basePath}/${id}/soft`);
// public getNotOnMenuCollection = (params: IMenuItemsNotOnMenuParams): Promise<IMenuItemCollection> =>
//   axios.get(`${basePath}/intersection`, { params });
// }

// export const menuItemTransport = new MenuItemTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IMenuItemCollection,
  IMenuItemCollectionFilter,
  IMenuItemCreateData,
  IMenuItemModel,
  IMenuItemsNotOnMenuParams,
  IMenuItemUpdateData,
} from 'entities/MenuItem/MenuItem.models';

const basePath = '/menu-items';

export const menuItemTransport = {
  get: (id: string): Promise<IMenuItemModel> => axios.get(`${basePath}/${id}`),
  add: (params: IMenuItemCreateData): Promise<IMenuItemModel> => axios.post(`${basePath}`, params),
  update: ({ id, ...rest }: IMenuItemUpdateData): Promise<IMenuItemModel> => axios.put(`${basePath}/${id}`, rest),
  deleteSoft: (id: string): Promise<void> => axios.delete(`${basePath}/${id}/soft`),
  getCollection: (filter?: IMenuItemCollectionFilter): Promise<IMenuItemCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`),
  getNotOnMenuCollection: (params: IMenuItemsNotOnMenuParams): Promise<IMenuItemCollection> =>
    axios.get(`${basePath}/intersection`, { params }),
};
