// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import {
//   IRatingCollection,
//   IRatingCollectionFilter,
//   IRatingCreateData,
//   IRatingModel,
//   IRatingUpdateData
// } from 'entities/Rating/Rating.models';

// const basePath = '/ratings';

// class RatingTransport extends BaseHttpTransport<
//   AxiosStatic,
//   IRatingModel,
//   IRatingCollection,
//   IRatingCreateData,
//   IRatingUpdateData,
//   IRatingCollectionFilter
// > {}

// export const ratingTransport = new RatingTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IRatingCollection, IRatingCollectionFilter, IRatingModel, IRatingUpdateData } from 'entities/Rating/Rating.models';

const basePath = '/ratings';

export const ratingTransport = {
  get: (id: string): Promise<IRatingModel> => axios.get(`${basePath}/${id}`),
  update: ({ id, ...rest }: IRatingUpdateData): Promise<IRatingModel> => axios.put(`${basePath}/${id}`, rest),
  getCollection: (filter?: IRatingCollectionFilter): Promise<IRatingCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`),
};
