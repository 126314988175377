import moment, { Moment } from 'moment';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';

export const dateFormatter = (date: string | undefined | null, dateFormat: string = 'DD MMMM YYYY, HH:mm') =>
  date ? moment(date).format(dateFormat) : '';
export const dateFormatterToUTC = (date: string, dateFormat: string = 'DD MMM YYYY, HH:mm') =>
  moment.utc(date, dateFormat).format();
export const dateDifference = (firstDate: string, secondDate: string) => moment(firstDate).diff(secondDate);
export const timeFormatter = (time: string | undefined | null, dateFormat: string = 'HH:mm') =>
  time ? moment(time, 'HH:mm:ss').format(dateFormat) : '';

export const rangeConfig: {
  label: React.ReactNode;
  value: RangeValueType<Moment>;
}[] = [
  { label: 'Today', value: [moment().startOf('day'), moment()] },
  { label: 'Yesterday', value: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')] },
  { label: 'This week', value: [moment().startOf('week'), moment().endOf('week')] },
  { label: 'This month', value: [moment().startOf('month'), moment().endOf('month')] },
  { label: 'Last week', value: [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')] },
  {
    label: 'Last month',
    value: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
  },
  { label: 'Last 7 days', value: [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')] },
  { label: 'Last 14 days', value: [moment().subtract(14, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')] },
  { label: 'Last 30 days', value: [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')] },
];
