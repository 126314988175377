// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import {
//   IPromoCollection,
//   IPromoCollectionFilter,
//   IPromoCreateData,
//   IPromoModel,
//   IPromoUpdateData,
// } from 'entities/Promo/Promo.models';
// import { IStoreModel } from 'entities/Store/Store.models';

// const basePath = '/promos';

// class PromoTransport extends BaseHttpTransport<
//   AxiosStatic,
//   IPromoModel,
//   IPromoCollection,
//   IPromoCreateData,
//   IPromoUpdateData,
//   IPromoCollectionFilter
// > {
//   public create = (data: IPromoCreateData): Promise<IPromoModel> => {
//     const { storesArrayValue, criteriaMenuItemsValue, bonusMenuItemValue, criteriaCategoriesValue } = data || {};
//     const stores = storesArrayValue?.map((item: IStoreModel) => item.id);
//     const params: IPromoCreateData = {
//       ...data,
//       stores,
//     };
//     if (criteriaMenuItemsValue) {
//       params.criteriaMenuItems = [criteriaMenuItemsValue.id];
//     }
//     if (bonusMenuItemValue) {
//       params.bonusMenuItem = bonusMenuItemValue.id;
//     }
//     if (criteriaCategoriesValue) {
//       params.criteriaCategories = [criteriaCategoriesValue.id];
//     }
//     return axios.post(basePath, params);
//   };
// }

// export const promoTransport = new PromoTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoCreateData,
  IPromoModel,
  IPromoUpdateData,
} from 'entities/Promo/Promo.models';
import { IStoreModel } from 'entities/Store/Store.models';

const basePath = '/promos';

export const promoTransport = {
  add: (data: IPromoCreateData): Promise<IPromoModel> => {
    const { storesArrayValue, criteriaMenuItemsValue, bonusMenuItemValue, criteriaCategoriesValue } = data || {};
    const stores = storesArrayValue?.map((item: IStoreModel) => item.id);
    const params: IPromoCreateData = {
      ...data,
      stores,
    };
    if (criteriaMenuItemsValue) {
      params.criteriaMenuItems = [criteriaMenuItemsValue.id];
    }
    if (bonusMenuItemValue) {
      params.bonusMenuItem = bonusMenuItemValue.id;
    }
    if (criteriaCategoriesValue) {
      params.criteriaCategories = [criteriaCategoriesValue.id];
    }
    return axios.post(basePath, params);
  },
  get: (id: string): Promise<IPromoModel> => axios.get(`${basePath}/${id}`),
  update: ({ id, ...rest }: IPromoUpdateData): Promise<IPromoModel> => axios.put(`${basePath}/${id}`, rest),
  delete: (id: string): Promise<void> => axios.delete(`${basePath}/${id}`),
  getCollection: (filter?: IPromoCollectionFilter): Promise<IPromoCollection> => axios.get(`${basePath}${objectToQuery(filter)}`),
};
