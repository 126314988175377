import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { QRTable } from 'entities/QR/components/QRTable';
import { IQRCollectionFilter, tableQRConfig } from 'entities/QR/QR.models';
import { StoreSelector } from 'entities/Store/components/Selector/StoreSelector';
import { IStoreModel, selectorStoreAllKeyId } from 'entities/Store/Store.models';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IQRCollectionFilter;
}

class QRTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      name: undefined,
      storeId: undefined,
    },
  };

  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IQRCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  setFilter = (partialFilter: Partial<IQRCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter, pageIndex: 1 };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    this.setFilter({ name: text });
  };

  onStoreSelectorChange = (item?: IStoreModel) => {
    const isAll = item?.id === selectorStoreAllKeyId;
    const storeId = item && !isAll ? item?.id : undefined;

    this.setFilter({ storeId });
  };

  render() {
    const { filter } = this.state;
    const { name, storeId } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row align="middle" gutter={32}>
              <Col span={8}>
                <Input.Search
                  placeholder="Search by name"
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Row className="flex-noWrap" justify="space-between" align="middle">
                  <span className="mr-4">Store</span>
                  <StoreSelector value={storeId} onChange={this.onStoreSelectorChange} />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={3} className="pr-0">
            <Link to={ERoutesPrivate.QRCreate}>
              <Button block type="primary" title="Create qr" className="mt-4">
                Create new QR
              </Button>
            </Link>
          </Col>
        </Row>
        <QRTable config={tableQRConfig} filter={filter} />
      </CommonLayout>
    );
  }
}

export const QRTablePage = withRouter(QRTablePageComponent);
