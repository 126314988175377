import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import {
  addAssetModel,
  deleteAssetModel,
  getAssetModel,
  setAssetModel,
  updateAssetModel,
} from 'app/store/reducers/asset.reducer';
import { AssetForm } from 'entities/Asset/components/Form/AssetForm';
import { AssetFormSkeleton } from 'entities/Asset/components/Skeleton/AssetFormSkeleton';
import { IAssetCreateData, IAssetUpdateData } from 'entities/Asset/Asset.models';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const AssetPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { assetModel, assetModelLoading } = useAppSelector((state) => state.asset);
  const { params } = match;
  const { id } = params;
  const assetName = assetModel?.name;
  const isEdit = !!id;

  const addAsset = (data: IAssetCreateData) => {
    dispatch(addAssetModel(data));
  };

  const updateAsset = (data: IAssetUpdateData) => {
    dispatch(updateAssetModel(data));
  };

  const deleteAsset = (id: string) => {
    dispatch(deleteAssetModel(id));
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getAssetModel(id));
    }

    return () => dispatch(setAssetModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Assets}`}>Asset list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !assetModelLoading ? (
                assetName
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Add new asset'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!assetModelLoading || !isEdit ? (
          <AssetForm createAsset={addAsset} updateAsset={updateAsset} deleteAsset={deleteAsset} assetId={id} />
        ) : (
          <AssetFormSkeleton isEdit={isEdit} />
        )}
      </Col>
    </CommonLayout>
  );
};
