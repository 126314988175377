// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import { IOrderCollection, IOrderCollectionFilter } from 'entities/Order/Order.models';

// const basePath = '/orders';

// class OrderTransport extends BaseHttpTransport<AxiosStatic, void, IOrderCollection, void, void, IOrderCollectionFilter> {}

// export const orderTransport = new OrderTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IOrderCollection, IOrderCollectionFilter } from 'entities/Order/Order.models';

const basePath = '/orders';

export const orderTransport = {
  getCollection: (filter?: IOrderCollectionFilter): Promise<IOrderCollection> => axios.get(`${basePath}${objectToQuery(filter)}`),
};
