import React, { useState } from 'react';
import { Button, Col, Form, notification, Row, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME, requireText, TIME_FORMAT } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';
import { dateFormatter, timeFormatter } from 'common/helpers/date.helper';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppSelector } from 'app/store/store.hooks';
import {
  EPromoCriteriaType,
  EPromoBonusType,
  IPromoUpdateData,
  EPromoStatusText,
  EPromoPeriodText,
} from 'entities/Promo/Promo.models';
import { selectBonus, selectCriteria, selectCustomerType, selectPromoType } from 'entities/Promo/Promo.helper';

interface IComponentProps {
  updatePromo?: (data: IPromoUpdateData) => void;
  deletePromo?: (id: string) => void;
}

const PromoEditFormComponent: React.FC<IComponentProps> = (props) => {
  const { promoModel, promoModelLoading, promoModelParams, promoModelError } = useAppSelector((state) => state.promo);
  const { updatePromo, deletePromo } = props;
  const {
    id,
    type,
    bonus,
    code,
    criteria,
    criteriaMenuItems,
    customerTarget,
    endDate,
    startDate,
    startTime,
    endTime,
    stores,
    isEnable,
    criteriaPrice,
    criteriaCategories,
    criteriaEveryPesos,
    fileName,
    description,
  } = promoModel || {};
  const submitButtonText = 'Save';
  const [enablePromo, setEnablePromo] = useState<boolean>(!!isEnable);
  const menuItemCriteriaValue = criteriaMenuItems && criteriaMenuItems[0]?.name;
  const categoryCriteriaValue = criteriaCategories && criteriaCategories[0]?.name;
  const menuItemBonusValue = bonus?.menuItem?.name;
  const bonusType = bonus?.bonusType;
  const bonusMaxPesos = bonus?.maxPesos;
  const bonusItemQuantity = bonus?.itemQuantity;
  const bonusMaxRedemptions = bonus?.maxRedemptions;
  const bonusTypeValue = selectBonus(bonusType as EPromoBonusType);
  const criteriaTypeValue = selectCriteria(criteria as EPromoCriteriaType);
  const isAdditionalBonusType = bonusType === EPromoBonusType.Additional;
  const isAdditionalNotOnMenuBonusType = bonusType === EPromoBonusType.AdditionalNotOnMenu;
  const isDiscountItemBonusType = bonusType === EPromoBonusType.DiscountItem;
  const isDiscountAllPesoBonusType = bonusType === EPromoBonusType.DiscountAllPeso;
  const isDiscountAllPercentBonusType = bonusType === EPromoBonusType.DiscountAllPercent;
  const startDateValue = startDate && dateFormatter(startDate, startTime ? DATE_FORMAT : DATE_FORMAT_WITH_TIME);
  const endDateValue = endDate && dateFormatter(endDate, endTime ? DATE_FORMAT : DATE_FORMAT_WITH_TIME);
  const startTimeValue = startTime && timeFormatter(startTime, TIME_FORMAT);
  const endTimeValue = endTime && timeFormatter(endTime, TIME_FORMAT);
  const promoTypeValue = selectPromoType(type);
  const promoCustomerTypeValue = selectCustomerType(customerTarget);
  const [form] = Form.useForm();
  const visibleValueText = enablePromo ? EPromoStatusText.Enable : EPromoStatusText.Disable;
  const promoPeriodText = startTimeValue && endTimeValue ? EPromoPeriodText.specificDateAndTime : EPromoPeriodText.specificDate;

  const { fields } = useFormMapper(['isEnable'], promoModel, promoModelParams, promoModelError);

  const editPromo = (values: any) => {
    const { isEnable } = values;
    if (id && updatePromo) {
      const params: IPromoUpdateData = {
        id,
        isEnable,
      };

      updatePromo(params);
    }
  };
  const removePromo = () => {
    if (id && deletePromo) {
      deletePromo(id);
    }
  };

  const onFieldsChange = (changedValues: any[]) => {
    const itemEnablePromo = changedValues.find((item) => item.name[0] === 'isEnable');
    if (itemEnablePromo) {
      setEnablePromo(itemEnablePromo.value);
    }
  };

  return (
    <Form
      className="mt-5"
      form={form}
      onFinish={editPromo}
      fields={fields}
      layout="vertical"
      autoComplete="off"
      onFieldsChange={onFieldsChange}
    >
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Promo type" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={promoTypeValue}>
              {promoTypeValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          {code && (
            <SkeletonFormItem label="Promo code" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={code}>
                {code}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Promo period" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={promoPeriodText}>
              {promoPeriodText}
            </span>
          </SkeletonFormItem>
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Start date" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={startDateValue || ''}>
              {startDateValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="End date" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={endDateValue || ''}>
              {endDateValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          {startTimeValue && (
            <SkeletonFormItem label="Start time" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={startTimeValue || ''}>
                {startTimeValue}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
        <Col span={12}>
          {endTimeValue && (
            <SkeletonFormItem label="End time" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={endTimeValue || ''}>
                {endTimeValue}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Promo description" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={description}>
              {description}
            </span>
          </SkeletonFormItem>
        </Col>
      </Row>
      <span className="basic__text_title" title="Eligibility">
        Eligibility
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Customer" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={promoCustomerTypeValue}>
              {promoCustomerTypeValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="Store" boldTitle>
            {stores?.length ? (
              stores?.map((item) => (
                <span key={item.id} className="font-family__default fs-xxs color-grayDark" title={item.storeName}>
                  {item.storeName} <br />
                </span>
              ))
            ) : (
              <span className="font-family__default fs-xxs color-grayDark" title="All stores">
                All stores
              </span>
            )}
          </SkeletonFormItem>
        </Col>
        {fileName && (
          <Col span={12}>
            <SkeletonFormItem label="CSV" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={fileName}>
                {fileName}
              </span>
            </SkeletonFormItem>
          </Col>
        )}
      </Row>
      <span className="basic__text_title" title="Redemption criteria">
        Redemption criteria
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Criterion" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={criteriaTypeValue}>
              {criteriaTypeValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          {menuItemCriteriaValue && (
            <SkeletonFormItem label="Menu item" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark">{menuItemCriteriaValue}</span>
            </SkeletonFormItem>
          )}
          {!!criteriaPrice && (
            <SkeletonFormItem label="Min order value (P)" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark">{criteriaPrice}</span>
            </SkeletonFormItem>
          )}
          {categoryCriteriaValue && (
            <SkeletonFormItem label="Category" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark">{categoryCriteriaValue}</span>
            </SkeletonFormItem>
          )}
          {!!criteriaEveryPesos && (
            <SkeletonFormItem label="X pesos (P)" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark">{criteriaEveryPesos}</span>
            </SkeletonFormItem>
          )}
        </Col>
      </Row>
      <span className="basic__text_title" title="Reward">
        Reward
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="Reward option" boldTitle>
            <span className="font-family__default fs-xxs color-grayDark" title={bonusTypeValue}>
              {bonusTypeValue}
            </span>
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          {menuItemBonusValue && (isAdditionalBonusType || isDiscountItemBonusType || isAdditionalNotOnMenuBonusType) && (
            <SkeletonFormItem label="Menu item" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={menuItemBonusValue}>
                {menuItemBonusValue}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          {bonusItemQuantity && (
            <SkeletonFormItem label="Item Quantity" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={`${bonusItemQuantity}`}>
                {bonusItemQuantity}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
        <Col span={12}>
          {bonusMaxRedemptions && (
            <SkeletonFormItem label="Max Redemptions" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={`${bonusMaxRedemptions}`}>
                {bonusMaxRedemptions}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
        <Col span={12}>
          {(isDiscountAllPesoBonusType || isDiscountAllPercentBonusType || isDiscountItemBonusType) && (
            <SkeletonFormItem label={`Discount (${isDiscountAllPercentBonusType ? '%' : 'P'})`} boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={`${bonus?.discount}`}>
                {bonus?.discount}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
        <Col span={12}>
          {isDiscountAllPercentBonusType && bonusMaxPesos && (
            <SkeletonFormItem label="Max Pesos (P)" boldTitle>
              <span className="font-family__default fs-xxs color-grayDark" title={`${bonus?.discount}`}>
                {bonusMaxPesos}
              </span>
            </SkeletonFormItem>
          )}
        </Col>
      </Row>
      <span className="basic__text_title" title="Promo status">
        Promo status
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: requireText,
              },
            ]}
          >
            <Row className="mt-4" align="middle">
              <Form.Item name="isEnable" noStyle valuePropName="checked">
                <Switch disabled={promoModelLoading} />
              </Form.Item>
              <div className="font-family__default fs-xxs color-grayDark ml-4">{visibleValueText}</div>
            </Row>
          </Form.Item>
        </Col>
      </Row>

      <Col span={16}>
        <Row className="flex-noWrap" justify="space-between" align="middle">
          <Row className="flex-noWrap">
            <Form.Item className="mb-0 mr-5">
              <Button
                htmlType="submit"
                type="primary"
                block
                disabled={promoModelLoading}
                loading={promoModelLoading}
                title={submitButtonText}
              >
                {submitButtonText}
              </Button>
            </Form.Item>

            <Link to={ERoutesPrivate.Promo}>
              <Button
                block
                disabled={promoModelLoading}
                loading={promoModelLoading}
                title="Cancel"
                onClick={notification.destroy}
              >
                Cancel
              </Button>
            </Link>
          </Row>
          <Button danger disabled={promoModelLoading} loading={promoModelLoading} title="Delete" onClick={removePromo}>
            Delete
          </Button>
        </Row>
      </Col>
    </Form>
  );
};

export const PromoEditForm = PromoEditFormComponent;
