import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import {
  ECategorySuccessMessage,
  ICategoryCollection,
  ICategoryCollectionFilter,
  ICategoryCreateData,
  ICategoryModel,
  ICategoryParamsModel,
  ICategoryUpdateData,
} from 'entities/Category/Category.models';
import { categoryTransport } from 'entities/Category/Category.transport';

export interface IState {
  categoryModelLoading: boolean;
  categoryModel: ICategoryModel | null;
  categoryModelError: IError | null;
  categoryModelParams: ICategoryParamsModel | null;
  categoryCollectionLoading: boolean;
  categoryCollection: ICategoryCollection | null;
  categoryCollectionError: IError | null;
  categoryCollectionParams: ICategoryCollectionFilter | null;
}

const initialState: IState = {
  categoryModelLoading: false,
  categoryModel: null,
  categoryModelError: null,
  categoryModelParams: null,
  categoryCollectionLoading: false,
  categoryCollection: null,
  categoryCollectionError: null,
  categoryCollectionParams: null,
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryModelLoading(state, action: PayloadAction<boolean>) {
      state.categoryModelLoading = action.payload;
    },
    setCategoryModel(state, action: PayloadAction<ICategoryModel | null>) {
      state.categoryModel = action.payload;
    },
    setCategoryModelError(state, action: PayloadAction<IError | null>) {
      state.categoryModelError = action.payload;
    },
    setCategoryModelParams(state, action: PayloadAction<ICategoryParamsModel | null>) {
      state.categoryModelParams = action.payload;
    },
    setCategoryCollectionLoading(state, action: PayloadAction<boolean>) {
      state.categoryCollectionLoading = action.payload;
    },
    setCategoryCollection(state, action: PayloadAction<ICategoryCollection | null>) {
      state.categoryCollection = action.payload;
    },
    setCategoryCollectionError(state, action: PayloadAction<IError | null>) {
      state.categoryCollectionError = action.payload;
    },
    setCategoryCollectionParams(state, action: PayloadAction<ICategoryCollectionFilter | null>) {
      state.categoryCollectionParams = action.payload;
    },
  },
});

export const {
  setCategoryModelLoading,
  setCategoryModel,
  setCategoryModelError,
  setCategoryModelParams,
  setCategoryCollectionLoading,
  setCategoryCollection,
  setCategoryCollectionError,
  setCategoryCollectionParams,
} = categorySlice.actions;
export default categorySlice.reducer;

export const getCategoryModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCategoryModelLoading(true));

    try {
      const categoryModel = await categoryTransport.get(id);

      dispatch(setCategoryModel(categoryModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCategoryModelError(_error));
    } finally {
      dispatch(setCategoryModelLoading(false));
    }
  }

  return thunk;
};

export const addCategoryModel = (params: ICategoryCreateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCategoryModelLoading(true));

    try {
      const categoryModel = await categoryTransport.add(params);

      dispatch(setCategoryModel(categoryModel));

      message.success(ECategorySuccessMessage.Create);
      history.push(`${ERoutesPrivate.Menu}?tab=categories`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCategoryModelError(_error));
    } finally {
      dispatch(setCategoryModelLoading(false));
    }
  }

  return thunk;
};

export const updateCategoryModel = (params: ICategoryUpdateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCategoryModelLoading(true));

    try {
      const categoryModel = await categoryTransport.update(params);

      dispatch(setCategoryModel(categoryModel));

      message.success(ECategorySuccessMessage.Edit);
      history.push(`${ERoutesPrivate.Menu}?tab=categories`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCategoryModelError(_error));
    } finally {
      dispatch(setCategoryModelLoading(false));
    }
  }

  return thunk;
};

export const deleteCategoryModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCategoryModelLoading(true));

    try {
      await categoryTransport.delete(id);

      dispatch(setCategoryModel(null));

      message.success(ECategorySuccessMessage.Delete);
      history.push(`${ERoutesPrivate.Menu}?tab=categories`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCategoryModelError(_error));
    } finally {
      dispatch(setCategoryModelLoading(false));
    }
  }

  return thunk;
};

export const getCategoryCollection = (filter?: ICategoryCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCategoryCollectionLoading(true));

    try {
      const categoryCollection = await categoryTransport.getCollection(filter);

      dispatch(setCategoryCollection(categoryCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCategoryCollectionError(_error));
    } finally {
      dispatch(setCategoryCollectionLoading(false));
    }
  }

  return thunk;
};
