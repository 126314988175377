import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { getOrderCollection, setOrderCollection } from 'app/store/reducers/order.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { IOrderCollectionFilter, IOrderModel } from 'entities/Order/Order.models';
import { OrderListItem } from 'entities/Order/components/OrderListItem';

interface IComponentProps {
  showCreateDate?: boolean;
  filter: IOrderCollectionFilter;
}

export const OrderList: React.FC<IComponentProps> = ({ filter, showCreateDate = false }) => {
  const dispatch = useAppDispatch();
  const { orderCollection, orderCollectionLoading } = useAppSelector((state) => state.order);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IOrderCollectionFilter = {
      recentOnly: true,
      orderField: 'pickUpTime',
      orderDirection: EBaseOrderDirection.ASC,
      ...filter,
      ...params,
    };

    dispatch(getOrderCollection(filterParams));
  };

  const renderListItem = (item: IOrderModel) => {
    return <OrderListItem item={item} showCreateDate={showCreateDate} />;
  };

  const clearCollection = () => {
    dispatch(setOrderCollection(null));
  };

  return (
    <InfiniteList
      elementId="basicLayout"
      filter={filter}
      collection={orderCollection}
      loading={orderCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      renderListItem={renderListItem}
      emptyText="No current orders"
    />
  );
};
