import React from 'react';
import { Row, Col } from 'antd';
import { RouteComponentProps } from 'react-router';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { StatisticStoreList } from 'entities/Statistic/components/StatisticStoreList';
import { IStoreModel, selectorStoreAllKeyId } from 'entities/Store/Store.models';
import { StoreSelector } from 'entities/Store/components/Selector/StoreSelector';
import { UserSelector } from 'entities/User/components/Selector/UserSelector';
import { EUserRole, IUserModel } from 'entities/User/User.models';
import { IStatisticCollectionFilter } from 'entities/Statistic/Statistic.models';

interface IFilter {
  pageIndex?: number;
  franchiseeId?: string;
  store?: Partial<IStoreModel>;
}

interface IComponentState {
  filter: IFilter;
}

type AllProps = RouteComponentProps;

export class StoreListSalesOpsPage extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      franchiseeId: undefined,
      store: undefined,
    },
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { franchiseeId, store } = filter;

    return (
      <FranchiseeCenterLayout>
        <Row justify="center" align="middle">
          <span className="franchisee__text_title" title="Stores">
            Stores
          </span>
        </Row>
        <div>
          <Col span={24} className="mt-5 mb-5">
            <div className="fs-xxs font-family__default mb-3">Franchisee</div>
            <UserSelector
              placeholder="Enter franchisee name"
              role={EUserRole.Franchisee}
              disabled={false}
              onChange={this.onChangeFranchisee}
            />
          </Col>
          <Col span={24} className="mt-5 mb-5">
            <div className="fs-xxs font-family__default mb-3">Store</div>
            <StoreSelector
              onChange={this.onChangeStore}
              value={store}
              franchiseeId={franchiseeId}
              defaultOpt={{ id: selectorStoreAllKeyId }}
              placeholder="Enter store name"
              allowClear={false}
            />
          </Col>
        </div>

        <StatisticStoreList isSalesOps filter={this.configureFilter()} />
      </FranchiseeCenterLayout>
    );
  }

  configureFilter = () => {
    const { filter } = this.state;
    const filterparams: IStatisticCollectionFilter = {};

    if (filter.franchiseeId) {
      filterparams.franchiseeId = filter.franchiseeId;
    }

    if (filter?.store) {
      filterparams.storeId = filter.store.id;
    }

    return filterparams;
  };

  onChangeFranchisee = (item?: IUserModel) => {
    this.setFilter({ franchiseeId: item?.id, store: undefined });
  };

  onChangeStore = (item?: IStoreModel) => {
    const isAll = item?.id === selectorStoreAllKeyId;
    const store = item && !isAll ? { id: item?.id, storeName: item?.storeName } : undefined;

    this.setFilter({ store });
  };

  setFilter = (partialFilter: Partial<IFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter, pageIndex: 1 };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };
}
