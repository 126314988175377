import React from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesPublic } from 'common/models/routesModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { resetPassword } from 'app/store/reducers/auth.reducer';
import { IAuthPasswordForgotParams } from 'entities/Auth/Auth.models';

export const PasswordForgotPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authPasswordRestoreLoading, authPasswordRestoreError, authPasswordRestoreParams } = useAppSelector(
    (state) => state.auth
  );

  const { fields } = useFormMapper(['email'], null, authPasswordRestoreParams, authPasswordRestoreError);

  const forgotPassword = (values: any) => {
    const { email } = values;
    const params: IAuthPasswordForgotParams = {
      email,
    };

    dispatch(resetPassword(params));
  };

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="font-family__default fs-md" title="Restore password">
          Restore password
        </h1>
      </Row>
      <Form onFinish={forgotPassword} fields={fields} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
          ]}
          name="email"
          label="Email"
        >
          <Input type="email" name="email" placeholder="Email" />
        </Form.Item>
        <Typography.Paragraph className="fs-xxxs mb-5">
          Write your email and we will send password recovery instructions to it.
        </Typography.Paragraph>
        <Form.Item>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Button htmlType="submit" block type="primary" title="Restore" disabled={authPasswordRestoreLoading}>
                Restore
              </Button>
            </Col>
            <Link to={ERoutesPublic.Login} className="font-family__default fs-xs basic__link ellipsis" title="Try to log in">
              Try to log in
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
