// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import {
//   ICategoryCollection,
//   ICategoryCollectionFilter,
//   ICategoryCreateData,
//   ICategoryModel,
//   ICategoryUpdateData
// } from 'entities/Category/Category.models';

// const basePath = '/categories';

// class CategoryTransport extends BaseHttpTransport<
//   AxiosStatic,
//   ICategoryModel,
//   ICategoryCollection,
//   ICategoryCreateData,
//   ICategoryUpdateData,
//   ICategoryCollectionFilter
// > {}

// export const categoryTransport = new CategoryTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ICategoryCollection,
  ICategoryCollectionFilter,
  ICategoryCreateData,
  ICategoryModel,
  ICategoryUpdateData,
} from 'entities/Category/Category.models';

const basePath = '/categories';

export const categoryTransport = {
  get: (id: string): Promise<ICategoryModel> => axios.get(`${basePath}/${id}`),
  add: (params: ICategoryCreateData): Promise<ICategoryModel> => axios.post(`${basePath}`, params),
  update: ({ id, ...rest }: ICategoryUpdateData): Promise<ICategoryModel> => axios.put(`${basePath}/${id}`, rest),
  delete: (id: string): Promise<void> => axios.delete(`${basePath}/${id}`),
  getCollection: (filter?: ICategoryCollectionFilter): Promise<ICategoryCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`),
};
