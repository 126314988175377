import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row } from 'antd';
import { Link } from 'react-router-dom';
import { formRules } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/Image/ImageUpload';
import { useFormMapper } from 'common/helpers/form.helper';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { useAppSelector } from 'app/store/store.hooks';
import { CategorySelector } from 'entities/Category/components/Selector/CategorySelector';
import { IMenuItemCreateData, IMenuItemUpdateData } from 'entities/MenuItem/MenuItem.models';

interface IComponentProps {
  createMenuItem?: (data: IMenuItemCreateData) => void;
  updateMenuItem?: (data: IMenuItemUpdateData) => void;
  deleteMenuItem?: () => void;
  menuItemId?: string;
}

export const MenuItemForm: React.FC<IComponentProps> = (props) => {
  const { menuItemModel, menuItemModelLoading, menuItemModelParams, menuItemModelError } = useAppSelector(
    (state) => state.menuItem
  );
  const { createMenuItem, updateMenuItem, menuItemId, deleteMenuItem } = props;
  const isUpdateForm = !!menuItemId;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';

  const memoizedValue = useMemo(
    () => ({
      ...menuItemModelParams,
      categoryData: menuItemModelParams?.category ? { id: menuItemModelParams?.category } : undefined,
    }),
    [menuItemModelParams]
  );
  const paramsValue = menuItemModelParams?.name ? memoizedValue : menuItemModelParams;

  const { fields } = useFormMapper(
    ['categoryData', 'name', 'description', 'image'],
    menuItemModel,
    paramsValue,
    menuItemModelError
  );

  const addMenuItem = (values: any) => {
    const { name, description, categoryData, image } = values;
    const newData: IMenuItemCreateData = {
      name,
      description,
      image,
      category: categoryData?.id,
    };

    createMenuItem && createMenuItem(newData);
  };

  const editMenuItem = (values: any) => {
    const { name, description, image } = values;

    if (menuItemId && updateMenuItem) {
      const newData: IMenuItemUpdateData = {
        id: menuItemId,
        name,
        description,
        image,
      };

      updateMenuItem(newData);
    }
  };

  return (
    <Form className="mt-5" onFinish={isUpdateForm ? editMenuItem : addMenuItem} fields={fields} layout="vertical">
      <Row gutter={[28, 0]}>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="categoryData" label="Category">
            <CategorySelector placeholder="Choose category" disabled={menuItemModelLoading || isUpdateForm} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              formRules.required,
              {
                min: 2,
                message: 'Item name must be longer than or equal 2 characters',
              },
              {
                max: 100,
                message: 'Item name must be shorter than or equal 100 characters',
              },
            ]}
            name="name"
            label="Item name"
          >
            <Input placeholder="Item name" name="name" type="text" disabled={menuItemModelLoading} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                min: 2,
                message: 'Description must be longer than or equal 2 characters',
              },
              {
                max: 400,
                message: 'Description must be shorter than or equal 400 characters',
              },
            ]}
            name="description"
            label="Description"
          >
            <Input.TextArea placeholder="Description" name="description" disabled={menuItemModelLoading} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item rules={[formRules.required]} name="image" label="Image">
        <ImageUpload />
      </Form.Item>
      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={menuItemModelLoading}
              loading={menuItemModelLoading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Items}`}>
              <Button disabled={menuItemModelLoading} loading={menuItemModelLoading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
        {isUpdateForm && (
          <Popconfirm
            disabled={menuItemModelLoading}
            title="Are you sure you want to delete the menu item?"
            okType="danger"
            onConfirm={deleteMenuItem}
            okText="Delete"
          >
            <Button danger disabled={menuItemModelLoading} loading={menuItemModelLoading} title="Delete">
              Delete
            </Button>
          </Popconfirm>
        )}
      </Row>
    </Form>
  );
};
