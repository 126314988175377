import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IBasePathParams } from 'common/models/requestModels';
import { AppDispatch, history } from 'app/store';
import {
  EUserSuccessMessage,
  IUserCollection,
  IUserCollectionFilter,
  IUserInviteData,
  IUserModel,
  IUserModelParams,
  IUserUpdateData,
  IUserUpdatePasswordData,
} from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

export interface IState {
  userModelLoading: boolean;
  userModel: IUserModel | null;
  userModelError: IError | null;
  userModelParams: IUserModelParams | null;
  userCollectionLoading: boolean;
  userCollection: IUserCollection | null;
  userCollectionError: IError | null;
  userCollectionParams: IUserCollectionFilter | null;
}

const initialState: IState = {
  userModelLoading: false,
  userModel: null,
  userModelError: null,
  userModelParams: null,
  userCollectionLoading: false,
  userCollection: null,
  userCollectionError: null,
  userCollectionParams: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserModelLoading(state, action: PayloadAction<boolean>) {
      state.userModelLoading = action.payload;
    },
    setUserModel(state, action: PayloadAction<IUserModel | null>) {
      state.userModel = action.payload;
    },
    setUserModelError(state, action: PayloadAction<IError | null>) {
      state.userModelError = action.payload;
    },
    setUserModelParams(state, action: PayloadAction<IUserModelParams | null>) {
      state.userModelParams = action.payload;
    },
    setUserCollectionLoading(state, action: PayloadAction<boolean>) {
      state.userCollectionLoading = action.payload;
    },
    setUserCollection(state, action: PayloadAction<IUserCollection | null>) {
      state.userCollection = action.payload;
    },
    setUserCollectionError(state, action: PayloadAction<IError | null>) {
      state.userCollectionError = action.payload;
    },
    setUserCollectionParams(state, action: PayloadAction<IUserCollectionFilter | null>) {
      state.userCollectionParams = action.payload;
    },
  },
});

export const {
  setUserModelLoading,
  setUserModel,
  setUserModelError,
  setUserModelParams,
  setUserCollectionLoading,
  setUserCollection,
  setUserCollectionError,
  setUserCollectionParams,
} = userSlice.actions;
export default userSlice.reducer;

export const getUserModel = (params: { id: string }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      const userModel = await userTransport.get(params);

      dispatch(setUserModel(userModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const updateUserModel = (params: { path: IBasePathParams; data: IUserUpdateData }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));
    dispatch(setUserModelParams(params));

    try {
      const userModel = await userTransport.update(params);

      dispatch(setUserModel(userModel));

      message.success(EUserSuccessMessage.Edit);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const deleteUserModel = (params: IBasePathParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      await userTransport.delete(params);

      dispatch(setUserModel(null));

      message.success(EUserSuccessMessage.Delete);
      history.push(ERoutesPrivate.Users);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const inviteUserModel = (params: { data: IUserInviteData }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));
    dispatch(setUserModelParams(params));

    try {
      await userTransport.invite(params);

      message.success(EUserSuccessMessage.Invite);
      dispatch(setUserModelParams(null));
      history.push(ERoutesPrivate.Users);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const reInviteUserModel = (params: IBasePathParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      await userTransport.reInvite(params);

      message.success(EUserSuccessMessage.ReInviteSend);
      history.push(ERoutesPrivate.Users);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const updatePassword = (params: { path: IBasePathParams; data: IUserUpdatePasswordData }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));
    dispatch(setUserModelParams(params));

    try {
      await userTransport.updatePassword(params);
      dispatch(setUserModelParams(null));
      message.success(EUserSuccessMessage.ChangePassword);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const getUserCollection = (filter?: IUserCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserCollectionLoading(true));

    try {
      const userCollection = await userTransport.getCollection(filter);

      dispatch(setUserCollection(userCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserCollectionError(_error));
    } finally {
      dispatch(setUserCollectionLoading(false));
    }
  }

  return thunk;
};
