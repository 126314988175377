import React from 'react';
import { Col, Row, Rate, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';
import { nameBuilder } from 'common/helpers/name.helper';
import { locationFormatter } from 'common/helpers/location.helper';
import { dateFormatter, dateDifference } from 'common/helpers/date.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { DATE_FORMAT_PICKUP } from 'common/const/common.const';
import { useAppSelector } from 'app/store/store.hooks';
import { ERatingStatusText, IRatingUpdateData } from 'entities/Rating/Rating.models';

interface IComponentProps {
  updateRating?: (data: IRatingUpdateData) => void;
  ratingId?: string;
}

type AllProps = IComponentProps;

const RatingFormComponent: React.FC<AllProps> = (props) => {
  const { ratingModel, ratingModelLoading } = useAppSelector((state) => state.rating);
  const { ratingId, updateRating } = props;
  const { store, isEnable, rate, customer, createdAt, feedback, updatedAt } = ratingModel || {};
  const customerName = nameBuilder(customer?.firstName, customer?.lastName);
  const franchiseeName = nameBuilder(store?.franchisee?.firstName, store?.franchisee?.lastName);
  const address = locationFormatter(store?.address) || 'Unknown address';
  const postedDate = createdAt && dateFormatter(createdAt, DATE_FORMAT_PICKUP);
  const lastEditDate = updatedAt && dateFormatter(updatedAt, DATE_FORMAT_PICKUP);
  const isEdited = !!(createdAt && updatedAt) && dateDifference(createdAt, updatedAt) !== 0;
  const visibleValueText = isEnable ? ERatingStatusText.Visible : ERatingStatusText.NotVisible;
  const roundedRate = rate?.toFixed(1);
  const roundedStoreRate = store?.rate?.toFixed(1);
  const customerId = customer?.userId;
  const formattedRate = roundedRate ? parseInt(roundedRate) : 0;

  const visibleToggle = (isEnable: boolean) => {
    if (ratingId && updateRating) {
      updateRating({ id: ratingId, isEnable });
    }
  };

  return (
    <Row gutter={[28, 10]}>
      <Col span={12}>
        {customerId && (
          <SkeletonFormItem label="Customer name">
            <Link to={`${ERoutesPrivate.Customers}/${customerId}`} className="font-family__default fs-xxs basic__link">
              {customerName}
            </Link>
          </SkeletonFormItem>
        )}
        <SkeletonFormItem label="Feedback">
          <span className="font-family__default fs-xxs color-grayDark">{feedback || 'No feedback'}</span>
        </SkeletonFormItem>
        <SkeletonFormItem label="Rating feedback">
          <Rate disabled defaultValue={formattedRate} />
        </SkeletonFormItem>
        {postedDate && (
          <SkeletonFormItem label="Posted">
            <span className="font-family__default fs-xxs color-grayDark">{postedDate}</span>
          </SkeletonFormItem>
        )}
        {isEdited && (
          <SkeletonFormItem label="Last edit">
            <span className="font-family__default fs-xxs color-grayDark">{lastEditDate}</span>
          </SkeletonFormItem>
        )}
        <SkeletonFormItem label="Visibility">
          <Row>
            <Switch onChange={(e) => visibleToggle(e)} disabled={ratingModelLoading} checked={isEnable} />
            <div className="font-family__default fs-xxs color-grayDark ml-4">{visibleValueText}</div>
          </Row>
        </SkeletonFormItem>
      </Col>

      <Col span={12}>
        <SkeletonFormItem label="Store name">
          <span className="font-family__default fs-xxs color-grayDark">{store?.storeName || 'Unknown store'}</span>
        </SkeletonFormItem>
        <SkeletonFormItem label="Store rating">
          <span className="font-family__default fs-xxs color-grayDark">{roundedStoreRate || 'Unknown rate'}</span>
        </SkeletonFormItem>
        <SkeletonFormItem label="Franchisee name">
          <span className="font-family__default fs-xxs color-grayDark">{franchiseeName}</span>
        </SkeletonFormItem>
        <SkeletonFormItem label="Store address">
          <span className="font-family__default fs-xxs color-grayDark">{address}</span>
        </SkeletonFormItem>
      </Col>
    </Row>
  );
};

export const RatingForm = RatingFormComponent;
