import React, { useEffect } from 'react';
import { Breadcrumb, Col } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getRatingModel, setRatingModel, updateRatingModel } from 'app/store/reducers/rating.reducer';
import { RatingForm } from 'entities/Rating/components/Form/RatingForm';
import { RatingFormSkeleton } from 'entities/Rating/components/Skeleton/RatingFormSkeleton';
import { IRatingUpdateData } from 'entities/Rating/Rating.models';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const RatingPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { ratingModelLoading } = useAppSelector((state) => state.rating);

  const updateRating = (data: IRatingUpdateData) => {
    dispatch(updateRatingModel(data));
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getRatingModel(id));
    }

    return () => dispatch(setRatingModel(null));
  }, []);

  const { params } = match;
  const { id } = params;
  const ratingPageTitle = 'Details';

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Rating}>Customers feedback</Breadcrumb.Item>
          <Breadcrumb.Item>{ratingPageTitle}</Breadcrumb.Item>
        </Breadcrumb>
        {!ratingModelLoading ? <RatingForm updateRating={updateRating} ratingId={id} /> : <RatingFormSkeleton />}
      </Col>
    </CommonLayout>
  );
};
