import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT_WITH_TIME } from 'common/const/common.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getQrCollection, setQrCollection } from 'app/store/reducers/qr.reducer';
import { IQRCollection, IQRCollectionFilter, IQRModel } from 'entities/QR/QR.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: IQRCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const QRTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { qrCollection, qrCollectionLoading } = useAppSelector((state) => state.qr);
  const [mappedCollection, setMappedCollection] = useState<IQRCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IQRCollectionFilter = { ...params };

    if (filter?.name) {
      filterParams.name = filter.name;
    }

    if (filter?.storeId) {
      filterParams.storeId = filter.storeId;
    }

    dispatch(getQrCollection(filterParams));
  };

  const getCollection = (qrCollection: IQRCollection | null) => {
    if (qrCollection?.data) {
      const mappedData: IQRModel[] = qrCollection.data.map((item: IQRModel) => {
        return {
          ...item,
          createdAt: dateFormatter(item.createdAt, DATE_FORMAT_WITH_TIME),
        };
      });
      return {
        ...qrCollection,
        data: mappedData,
      };
    } else {
      return qrCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchObject = queryToObject<IQRCollectionFilter>({
      pageIndex: 1,
      name: undefined,
      storeId: undefined,
    });
    const search = objectToQuery({ ...searchObject, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IQRModel) => {
    history.push(`${ERoutesPrivate.QR}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setQrCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(qrCollection));
  }, [qrCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={qrCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const QRTable = withRouter(QRTableComponent);
