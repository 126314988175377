import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { nameBuilder } from 'common/helpers/name.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getRatingCollection, setRatingCollection } from 'app/store/reducers/rating.reducer';
import { ERatingStatusText, IRatingCollection, IRatingCollectionFilter, IRatingModel } from 'entities/Rating/Rating.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: IRatingCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const RatingTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { ratingCollection, ratingCollectionLoading } = useAppSelector((state) => state.rating);
  const [mappedCollection, setMappedCollection] = useState<IRatingCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IRatingCollectionFilter = { ...params, orderDirection: EBaseOrderDirection.ASC };

    if (filter?.storeSearch) {
      filterParams.storeSearch = filter?.storeSearch;
    }

    if (filter?.isEnable !== undefined) {
      filterParams.isEnable = filter?.isEnable;
    }

    if (filter?.franchisee) {
      filterParams.franchisee = filter?.franchisee;
    }

    dispatch(getRatingCollection(filterParams));
  };

  const getCollection = (ratingCollection: IRatingCollection | null) => {
    if (ratingCollection?.data) {
      const mappedData: IRatingModel[] = ratingCollection?.data.map((item) => {
        return {
          ...item,
          storeCode: item?.store?.storeCode,
          storeName: item?.store?.storeName,
          franchisee: nameBuilder(item?.store?.franchisee?.firstName, item?.store?.franchisee?.lastName),
          customerName: nameBuilder(item.customer?.firstName, item.customer?.lastName),
          rating: item?.rate,
          feedback: item?.feedback || 'No feedback',
          visibility: item?.isEnable ? ERatingStatusText.Visible : ERatingStatusText.NotVisible,
        };
      });

      return {
        ...ratingCollection,
        data: mappedData,
      };
    } else {
      return ratingCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IRatingCollectionFilter>({
      storeSearch: undefined,
      franchiseeValue: undefined,
      isEnable: undefined,
      pageIndex: 1,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IRatingModel) => {
    history.push(`${ERoutesPrivate.Rating}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setRatingCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(ratingCollection));
  }, [ratingCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={ratingCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const RatingTable = withRouter(RatingTableComponent);
