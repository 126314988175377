import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { Link } from 'react-router-dom';
import { formRules } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppSelector } from 'app/store/store.hooks';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { ECategoryType, ECategoryTypeText, ICategoryCreateData, ICategoryUpdateData } from 'entities/Category/Category.models';

interface IComponentProps {
  createCategory: (data: ICategoryCreateData) => void;
  updateCategory: (data: ICategoryUpdateData) => void;
  deleteCategory: () => void;
  typeForm: ECommonFormType;
}

const CategoryFormComponent: React.FC<IComponentProps> = (props) => {
  const { categoryModel, categoryModelLoading, categoryModelParams, categoryModelError } = useAppSelector(
    (state) => state.category
  );
  const { createCategory, updateCategory, typeForm, deleteCategory } = props;
  const categoryId = categoryModel?.id;
  const isCreateForm = typeForm === ECommonFormType.Add;
  const memoizedValue = useMemo(() => ({ type: ECategoryType.DisableAddOns, ...categoryModelParams }), [categoryModelParams]);
  const paramsValue = isCreateForm ? memoizedValue : categoryModelParams;
  const { fields } = useFormMapper(['name', 'type'], categoryModel, paramsValue, categoryModelError);

  const addCategory = (values: any) => {
    const { name, type } = values;
    const data: ICategoryCreateData = {
      name,
      type,
    };
    createCategory && createCategory(data);
  };

  const editCategory = (values: any) => {
    const { name } = values;

    if (categoryId) {
      const data: ICategoryUpdateData = {
        name,
        id: categoryId,
      };

      updateCategory && updateCategory(data);
    }
  };

  return (
    <Form className="mt-5" onFinish={isCreateForm ? addCategory : editCategory} fields={fields} layout="vertical">
      <Form.Item
        rules={[
          formRules.required,
          {
            min: 2,
            message: 'Category name must be longer than or equal 2 characters',
          },
          {
            max: 128,
            message: 'Category name must be shorter than or equal 128 characters',
          },
        ]}
        name="name"
        label="Category name"
      >
        <Input name="name" type="text" placeholder="Category name" disabled={categoryModelLoading} />
      </Form.Item>
      <Form.Item name="type">
        <Radio.Group disabled={categoryModelLoading || !isCreateForm}>
          <Radio value={ECategoryType.DisableAddOns} className="d-block mb-3">
            {ECategoryTypeText.DisableAddOns}
          </Radio>
          <Radio value={ECategoryType.IsAddOns} className="d-block mb-3">
            {ECategoryTypeText.IsAddOns}
          </Radio>
          <Radio value={ECategoryType.EnableAddOns} className="d-block">
            {ECategoryTypeText.EnableAddOns}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <Col span={12}>
          <Row className="flex-noWrap">
            <Form.Item className="mb-0 mr-5">
              <Button
                htmlType="submit"
                type="primary"
                disabled={categoryModelLoading}
                loading={categoryModelLoading}
                title={isCreateForm ? 'Add' : 'Save'}
              >
                {isCreateForm ? 'Add' : 'Save'}
              </Button>
            </Form.Item>

            <Link to={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Categories}`}>
              <Button disabled={categoryModelLoading} loading={categoryModelLoading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Row>
        </Col>
        {!isCreateForm && (
          <Button danger disabled={categoryModelLoading} loading={categoryModelLoading} title="Delete" onClick={deleteCategory}>
            Delete
          </Button>
        )}
      </Row>
    </Form>
  );
};

export const CategoryForm = CategoryFormComponent;
