import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { getMenuItemCollection, setMenuItemCollection } from 'app/store/reducers/menuItem.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { IMenuItemCollection, IMenuItemCollectionFilter, IMenuItemModel } from 'entities/MenuItem/MenuItem.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter?: IMenuItemCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const MenuItemTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { menuItemCollection, menuItemCollectionLoading } = useAppSelector((state) => state.menuItem);
  const [mappedCollection, setMappedCollection] = useState<IMenuItemCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    dispatch(getMenuItemCollection({ ...params, isDeleted: false }));
  };

  const getCollection = (menuItemCollection: IMenuItemCollection | null) => {
    if (menuItemCollection?.data) {
      const mappedData: IMenuItemModel[] = menuItemCollection.data.map((item) => {
        return {
          ...item,
          categoryName: item?.categoryData?.name,
        };
      });

      return {
        ...menuItemCollection,
        data: mappedData,
      };
    } else {
      return menuItemCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IMenuItemModel) => {
    history.push(`${ERoutesPrivate.MenuItem}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setMenuItemCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(menuItemCollection));
  }, [menuItemCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={menuItemCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const MenuItemTable = withRouter(MenuItemTableComponent);
