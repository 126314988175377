import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import {
  deleteUserModel,
  getUserModel,
  inviteUserModel,
  reInviteUserModel,
  setUserModel,
  updateUserModel,
} from 'app/store/reducers/user.reducer';
import {
  EUserRole,
  EUserStatus,
  EUserStatusText,
  IFranchiseeData,
  IUserInviteData,
  IUserUpdateData,
} from 'entities/User/User.models';
import { UserFormSkeleton } from 'entities/User/components/Skeleton/UserFormSkeleton';
import { UserForm } from 'entities/User/components/Form/UserForm';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const UserPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { userModel, userModelLoading } = useAppSelector((state) => state.user);
  const { params } = match;
  const { id } = params;
  const isEdit = !!id;
  const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;
  const isBlocked = userModel?.status === EUserStatus.Blocked;
  const isPending = userModel?.status === EUserStatus.Pending;
  const name = nameBuilder(userModel?.firstName, userModel?.lastName);
  const role = userModel ? `${userModel.role.charAt(0).toUpperCase() + userModel.role.slice(1)}` : 'Unknown role';
  const statusBlocked = isBlocked ? `(${EUserStatusText.Blocked})` : isPending ? `(${EUserStatusText.Pending})` : '';
  const title = isEdit ? `${name} - ${role} ${statusBlocked}` : 'Invite new user';

  const inviteUser = (data: IUserInviteData) => {
    dispatch(inviteUserModel({ data }));
  };

  const updateUser = (data: IUserUpdateData) => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(updateUserModel({ path: { id }, data }));
    }
  };

  const reInviteUser = () => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(reInviteUserModel({ id }));
    }
  };

  const blockUser = () => {
    const isBlocked = userModel?.status === EUserStatus.Blocked;
    const role = userModel?.role;
    const firstName = userModel?.firstName;
    const lastName = userModel?.lastName;
    const phone = userModel?.phone;
    const franchisees = userModel?.franchisees;

    if (firstName && lastName && phone) {
      const status = isBlocked ? EUserStatus.Active : EUserStatus.Blocked;
      const vals: IUserUpdateData = {
        firstName,
        lastName,
        phone,
        status,
      };

      if (role === EUserRole.SalesOps) {
        vals.franchisees = franchisees?.map((franchisee: IFranchiseeData) => franchisee.id);
      }

      updateUser(vals);
    }
  };

  const deleteUser = () => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(deleteUserModel({ id }));
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getUserModel({ id }));
    }

    return () => dispatch(setUserModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={16}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Users}>User list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !userModelLoading ? (
                title
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Invite new user'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!userModelLoading || !isEdit ? (
          <UserForm
            typeForm={typeForm}
            inviteUser={inviteUser}
            updateUser={updateUser}
            deleteUser={deleteUser}
            reInviteUser={reInviteUser}
            blockUser={blockUser}
          />
        ) : (
          <UserFormSkeleton />
        )}
      </Col>
    </CommonLayout>
  );
};
