// import React from 'react';
// import { Empty, List, Table } from 'antd';
// import InfiniteScroll from 'react-infinite-scroller';
// import { LoadingSpin } from 'common/components/LoadingSpin';
// import { IBaseFilterModel } from 'common/models/requestModels';

// interface IComponentProps<Model, Collection extends { data: Model[]; meta: { count?: number } }, Filter extends any = undefined> {
//   elementId: string;
//   filter?: Filter;
//   pageSize?: number;
//   isReverse?: boolean;
//   threshold?: number;
//   isTable?: boolean;
//   columns?: object[];
//   emptyText?: string;
// }

// interface IComponentState {
//   pageIndex: number;
// }

// export abstract class InfiniteList<
//   Collection extends { data: Model[]; meta: { count?: number } },
//   Model extends object,
//   IProps,
//   Filter extends any = undefined
// > extends React.PureComponent<IComponentProps<Model, Collection, Filter> & IProps, IComponentState> {
//   constructor(props: IComponentProps<Model, Collection, Filter> & IProps) {
//     super(props);
//     this.state = { pageIndex: 0 };
//   }

//   componentDidMount(): void {
//     this.loadData();
//   }

//   componentWillUnmount(): void {
//     this.clearCollection();
//   }

//   componentDidUpdate(prevProps: Readonly<IComponentProps<Model, Collection, Filter> & IProps>) {
//     if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
//       this.setState({ pageIndex: 0 }, this.loadData);
//     }
//   }

//   render() {
//     const { elementId, isReverse, threshold, isTable = false, columns, emptyText } = this.props;
//     const collection = this.getCollection();
//     const items = collection?.data || [];
//     const count = collection?.meta?.count || 0;
//     const hasMore = !this.loading && items.length < count;

//     return (
//       <InfiniteScroll
//         pageStart={1}
//         loadMore={this.loadData}
//         hasMore={hasMore}
//         useWindow={false}
//         isReverse={isReverse}
//         threshold={threshold}
//         getScrollParent={() => document.getElementById(elementId)}
//       >
//         {this.loading && isReverse && <LoadingSpin />}
//         {isTable && columns ? (
//           <Table dataSource={items} columns={columns} pagination={false} bordered loading={loading} />
//         ) : (
//           (collection || !this.loading) && (
//             <List
//               itemLayout="vertical"
//               dataSource={items}
//               renderItem={this.renderListItem}
//               locale={{
//                 emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />,
//               }}
//             />
//           )
//         )}
//         {this.loading && !isReverse && !isTable && <LoadingSpin />}
//       </InfiniteScroll>
//     );
//   }

//   loadData = async () => {
//     const collection = this.getCollection();
//     const { pageSize = 10, loading } = this.props;
//     const { pageIndex } = this.state;
//     const count = collection?.meta?.count || 0;
//     const hasMore = !loading && (!count || count > pageSize * pageIndex);

//     if (hasMore) {
//       const params: IBaseFilterModel = {
//         limit: pageSize,
//         offset: pageSize * pageIndex,
//       };

//       await this.loadCollection(params);
//       this.setState((state) => ({ pageIndex: state.pageIndex + 1 }));
//     }
//   };

//   // eslint-disable-next-line @typescript-eslint/no-empty-function
//   clearCollection: () => void = () => {};
//   abstract getCollection: () => Collection | null;
//   abstract loadCollection: (params: IBaseFilterModel) => void;
//   abstract renderListItem: (model: Model) => JSX.Element;
//   abstract loading: boolean;
// }

import React, { useEffect, useState } from 'react';
import { Empty, List, Table } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { IBaseFilterModel } from 'common/models/requestModels';

interface IInfiniteListProps<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model extends object,
  Filter extends any = undefined
> {
  elementId: string;
  filter?: Filter;
  pageSize?: number;
  isReverse?: boolean;
  threshold?: number;
  isTable?: boolean;
  columns?: object[];
  emptyText?: string;
  collection: Collection | null;
  loadCollection: (params: IBaseFilterModel) => void;
  clearCollection: () => void;
  renderListItem: (model: Model) => JSX.Element;
  loading: boolean;
}

export function InfiniteList<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model extends object,
  Filter extends any = undefined
>({
  elementId,
  filter,
  isReverse,
  threshold,
  isTable = false,
  columns,
  emptyText,
  collection,
  loadCollection,
  clearCollection,
  renderListItem,
  loading,
}: IInfiniteListProps<Collection, Model, Filter>) {
  const [updatedFilter, setUpdatedFilter] = useState<Filter | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(0);
  const items = collection?.data || [];
  const count = collection?.meta?.count || 0;
  const hasMore = !loading && items.length < count;

  const loadData = (pageIndex: number, pageSize: number = 10) => {
    const hasMore = !loading && (!count || count > pageSize * pageIndex);

    if (hasMore) {
      const params: IBaseFilterModel = {
        limit: pageSize,
        offset: pageSize * pageIndex,
      };

      setPageIndex((prevIndex) => prevIndex + 1);
      loadCollection(params);
    }
  };

  useEffect(() => {
    return () => {
      clearCollection();
    };
  }, []);

  useEffect(() => {
    if (JSON.stringify(updatedFilter) !== JSON.stringify(filter)) {
      setPageIndex(0);
      setUpdatedFilter(filter);
      loadData(0, 10);
    } else {
      loadData(pageIndex, 10);
    }
  }, [filter, pageIndex]);

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={loadData}
      hasMore={hasMore}
      useWindow={false}
      isReverse={isReverse}
      threshold={threshold}
      getScrollParent={() => document.getElementById(elementId)}
    >
      {loading && isReverse && <LoadingSpin />}
      {isTable && columns ? (
        <Table dataSource={items} columns={columns} pagination={false} bordered loading={loading} />
      ) : (
        (collection || !loading) && (
          <List
            itemLayout="vertical"
            dataSource={items}
            renderItem={renderListItem}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />,
            }}
          />
        )
      )}
      {loading && !isReverse && !isTable && <LoadingSpin />}
    </InfiniteScroll>
  );
}
