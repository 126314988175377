// import { BaseHttpTransport } from '@axmit/transport';
// import axios, { AxiosStatic } from 'axios';
// import { ICustomerModel, ICustomerUpdateStatusParams } from 'entities/Customer/Customer.models';
// import { ICustomerCollection, ICustomerCollectionFilter } from 'entities/Customer/Customer.models';

// const basePath = '/customers';

// class CustomerTransport extends BaseHttpTransport<
//   AxiosStatic,
//   ICustomerModel,
//   ICustomerCollection,
//   void,
//   void,
//   ICustomerCollectionFilter
// > {
//   public updateStatus = (params: ICustomerUpdateStatusParams) => axios.put(`${basePath}/${params.id}/change-status`, params);
// }

// export const customerTransport = new CustomerTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ICustomerModel,
  ICustomerUpdateStatusParams,
  ICustomerCollection,
  ICustomerCollectionFilter,
} from 'entities/Customer/Customer.models';

const basePath = '/customers';

export const customerTransport = {
  get: (id: string): Promise<ICustomerModel> => axios.get(`${basePath}/${id}`),
  getCollection: (filter?: ICustomerCollectionFilter): Promise<ICustomerCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`),
  updateStatus: (params: ICustomerUpdateStatusParams): Promise<ICustomerModel> =>
    axios.put(`${basePath}/${params.id}/change-status`, params),
};
