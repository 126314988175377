import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { getRatingCollection, setRatingCollection } from 'app/store/reducers/rating.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { StoreFeedbackListItem } from 'entities/Rating/components/Franchisee/StoreFeedbackListItem';
import { IRatingCollectionFilter, IRatingModel } from 'entities/Rating/Rating.models';

interface IComponentProps {
  filter?: IRatingCollectionFilter;
}

export const StoreFeedbackList: React.FC<IComponentProps> = ({ filter }) => {
  const dispatch = useAppDispatch();
  const { ratingCollection, ratingCollectionLoading } = useAppSelector((state) => state.rating);

  const loadCollection = (params: IBaseFilterModel) => {
    const { dateTo, dateFrom, order, store, isEnable } = filter || {};
    const filterParams: IRatingCollectionFilter = {
      ...params,
    };

    if (dateTo && dateFrom) {
      filterParams.dateFrom = dateFrom;
      filterParams.dateTo = dateTo;
    }

    if (order) {
      filterParams.orderField = 'rate';
      filterParams.orderDirection = order;
    }

    if (store) {
      filterParams.store = store;
    }

    if (isEnable !== undefined) {
      filterParams.isEnable = isEnable;
    }

    dispatch(getRatingCollection(filterParams));
  };

  const renderListItem = (item: IRatingModel) => {
    return <StoreFeedbackListItem item={item} />;
  };

  const clearCollection = () => {
    dispatch(setRatingCollection(null));
  };

  return (
    <InfiniteList
      elementId="basicLayout"
      filter={filter}
      collection={ratingCollection}
      loading={ratingCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      renderListItem={renderListItem}
    />
  );
};
