import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT_WITH_TIME } from 'common/const/common.const';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getPromoCollection, setPromoCollection } from 'app/store/reducers/promo.reducer';
import {
  EPromoBonusType,
  EPromoCriteriaType,
  EPromoStatusText,
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoModel,
} from 'entities/Promo/Promo.models';
import { selectBonus, selectCriteria, selectPromoType } from 'entities/Promo/Promo.helper';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: IPromoCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const PromoTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { promoCollection, promoCollectionLoading } = useAppSelector((state) => state.promo);
  const [mappedCollection, setMappedCollection] = useState<IPromoCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IPromoCollectionFilter = { ...params };

    if (filter?.name) {
      filterParams.name = filter?.name;
    }
    if (filter?.type) {
      filterParams.type = filter?.type;
    }
    if (filter?.bonusType) {
      filterParams.bonusType = filter?.bonusType;
    }
    if (filter?.criteria) {
      filterParams.criteria = filter?.criteria;
    }
    if (filter?.isEnable !== undefined) {
      filterParams.isEnable = filter?.isEnable;
    }

    dispatch(getPromoCollection(filterParams));
  };

  const getCollection = (promoCollection: IPromoCollection | null) => {
    if (promoCollection?.data) {
      const mappedData: IPromoModel[] = promoCollection.data.map((item: IPromoModel) => {
        return {
          ...item,
          startDate: dateFormatter(item.startDate, DATE_FORMAT_WITH_TIME),
          endDate: dateFormatter(item.endDate, DATE_FORMAT_WITH_TIME),
          typeText: selectPromoType(item?.type),
          status: item.isEnable ? EPromoStatusText.Enable : EPromoStatusText.Disable,
          bonusType: selectBonus(item.bonus.bonusType as EPromoBonusType),
          criteria: selectCriteria(item.criteria as EPromoCriteriaType),
        };
      });
      return {
        ...promoCollection,
        data: mappedData,
      };
    } else {
      return promoCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchObject = queryToObject<IPromoCollectionFilter>({
      pageIndex: 1,
      name: undefined,
      type: undefined,
      criteria: undefined,
      customerTarget: undefined,
      bonusType: undefined,
      isEnable: undefined,
    });
    const search = objectToQuery({ ...searchObject, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IPromoModel) => {
    history.push(`${ERoutesPrivate.Promo}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setPromoCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(promoCollection));
  }, [promoCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={promoCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const PromoTable = withRouter(PromoTableComponent);
