import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { locationFormatter } from 'common/helpers/location.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getStoreCollection, setStoreCollection } from 'app/store/reducers/store.reducer';
import {
  EStoreStatus,
  EStoreStatusText,
  IStoreCollection,
  IStoreCollectionFilter,
  IStoreModel,
} from 'entities/Store/Store.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: IStoreCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const StoreTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { storeCollection, storeCollectionLoading } = useAppSelector((state) => state.store);
  const [mappedCollection, setMappedCollection] = useState<IStoreCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IStoreCollectionFilter = { ...params, orderField: 'storeCode', orderDirection: EBaseOrderDirection.ASC };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }

    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }

    if (filter?.franchisee?.id) {
      filterParams.franchiseeId = filter?.franchisee.id;
    }

    dispatch(getStoreCollection(filterParams));
  };

  const getCollection = (storeCollection: IStoreCollection | null) => {
    if (storeCollection?.data) {
      const mappedData: IStoreModel[] = storeCollection.data.map((item) => {
        return {
          ...item,
          franchiseeName: `${item?.franchisee?.firstName || ''} ${item?.franchisee?.lastName || ''}`,
          addressName: locationFormatter(item.address),
          status: selectStatus(item?.status),
        };
      });
      return {
        ...storeCollection,
        data: mappedData,
      };
    } else {
      return storeCollection;
    }
  };

  const selectStatus = (status?: string): any => {
    switch (status) {
      case EStoreStatus.New:
        return EStoreStatusText.New;

      case EStoreStatus.Unavailable:
        return EStoreStatusText.Unavailable;

      case EStoreStatus.Active:
        return EStoreStatusText.Active;

      case EStoreStatus.Archived:
        return EStoreStatusText.Archived;

      default:
        return EStoreStatusText.Unavailable;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchObject = queryToObject<IStoreCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      statuses: undefined,
      franchisee: undefined,
    });
    const search = objectToQuery({ ...searchObject, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IStoreModel) => {
    history.push(`${ERoutesPrivate.Stores}/${item.shortStoreId}`);
  };

  const clearCollection = () => {
    return dispatch(setStoreCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(storeCollection));
  }, [storeCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={storeCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const StoreTable = withRouter(StoreTableComponent);
