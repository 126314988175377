import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getQrModel, setQrModel } from 'app/store/reducers/qr.reducer';
import { QRForm } from 'entities/QR/components/Form/QRForm';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const QRPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { qrModel, qrModelLoading } = useAppSelector((state) => state.qr);

  const { params } = match;
  const { id } = params;
  const isEdit = !!id;

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getQrModel(id));
    }

    return () => dispatch(setQrModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={`${ERoutesPrivate.QR}`}>QRs</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !qrModelLoading ? (
                qrModel?.name
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Create new QR'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>

        <QRForm qrId={id} />
      </Col>
    </CommonLayout>
  );
};
