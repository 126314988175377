import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import {
  addCategoryModel,
  deleteCategoryModel,
  getCategoryModel,
  setCategoryModel,
  updateCategoryModel,
} from 'app/store/reducers/category.reducer';
import { CategoryFormSkeleton } from 'entities/Category/components/Skeleton/CategoryFormSkeleton';
import { CategoryForm } from 'entities/Category/components/Form/CategoryForm';
import { ICategoryCreateData, ICategoryUpdateData } from 'entities/Category/Category.models';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const CategoryPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { categoryModel, categoryModelLoading } = useAppSelector((state) => state.category);
  const { params } = match;
  const { id } = params;
  const categoryName = categoryModel?.name;
  const isEdit = !!id;
  const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

  const addCategory = (data: ICategoryCreateData) => {
    dispatch(addCategoryModel(data));
  };

  const updateCategory = (data: ICategoryUpdateData) => {
    dispatch(updateCategoryModel(data));
  };

  const deleteCategory = () => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(deleteCategoryModel(id));
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getCategoryModel(id));
    }

    return () => dispatch(setCategoryModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Categories}`}>Category list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !categoryModelLoading ? (
                categoryName
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Add new category'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!categoryModelLoading || !isEdit ? (
          <CategoryForm
            typeForm={typeForm}
            createCategory={addCategory}
            updateCategory={updateCategory}
            deleteCategory={deleteCategory}
          />
        ) : (
          <CategoryFormSkeleton typeForm={typeForm} />
        )}
      </Col>
    </CommonLayout>
  );
};
