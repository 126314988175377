import React from 'react';
import { Row, Col, Button } from 'antd';
import { useMediaPredicate } from 'react-media-hook';
import { Link } from 'react-router-dom';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import { HeaderMenuWrapperMob } from 'common/components/Header/HeaderMenuWrapperMob';
import { MEDIA_MOBILE } from 'common/const/common.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { logout } from 'app/store/reducers/auth.reducer';

export const HeaderFranchisee: React.FC = () => {
  // ---> Leave as is. Just to update history and path on-the-go
  useAppSelector((state) => state.router);
  // <---
  const dispatch = useAppDispatch();
  const { authModel } = useAppSelector((state) => state.auth);
  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);
  const authorized = authModel && Object.keys(authModel).length !== 0;
  const visibleLogout = authorized && !isMobile;
  const visibleLogin = !authorized && !isMobile;
  const menuLayout = { lg: 20, md: 19, sm: 17, xs: 16 };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <header>
      <div className="ant-menu header-franchisee">
        <Row className="basic__desktop-wrapper flex-noWrap height-full" justify="space-between" align="middle">
          <Col {...menuLayout}>{isMobile ? <HeaderMenuWrapperMob /> : <HeaderMenu />}</Col>

          <Row justify="end" align="middle">
            {visibleLogout && (
              <Button onClick={onLogout} className="header-franchisee__desktop-logout-btn pr-0">
                Log out
              </Button>
            )}
            {visibleLogin && (
              <Link to={ERoutesPublic.Login}>
                <Button className="header-franchisee__desktop-logout-btn pr-0">Log In</Button>
              </Link>
            )}
          </Row>
        </Row>
      </div>
    </header>
  );
};
