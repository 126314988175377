import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';
import { PromoTable } from 'entities/Promo/components/PromoTable';
import {
  EPromoBonusType,
  EPromoCriteriaType,
  EPromoStatusText,
  EPromoType,
  IPromoCollectionFilter,
  tablePromoConfig,
} from 'entities/Promo/Promo.models';
import { PromoTypeSelector } from 'entities/Promo/components/Selector/PromoTypeSelector';
import { PromoBonusTypeSelector } from 'entities/Promo/components/Selector/PromoBonusTypeSelector';
import { PromoCriteriaTypeSelector } from 'entities/Promo/components/Selector/PromoCriteriaTypeSelector';
import { PromoStausSelector } from 'entities/Promo/components/Selector/PromoStatusSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IPromoCollectionFilter;
}

class PromoPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      name: undefined,
      type: undefined,
      criteria: undefined,
      customerTarget: undefined,
      bonusType: undefined,
      isEnable: undefined,
    },
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IPromoCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const promoStatus = filter.isEnable;
    const statusValue = promoStatus === undefined ? undefined : promoStatus ? EPromoStatusText.Enable : EPromoStatusText.Disable;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={24} className="width-full promo__filter-bar m-0" align="middle">
          <Col span={5} className="pl-0">
            <SkeletonFormItem label="Search by promo name">
              <Input.Search
                defaultValue={filter.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
              />
            </SkeletonFormItem>
          </Col>
          <Col span={5}>
            <SkeletonFormItem label="Criterion">
              <PromoCriteriaTypeSelector onChange={this.onChangeCriteria} defaultValue={filter.criteria} allowClear />
            </SkeletonFormItem>
          </Col>
          <Col span={5}>
            <SkeletonFormItem label="Reward">
              <PromoBonusTypeSelector onChange={this.onChangeBonusType} defaultValue={filter.bonusType} allowClear />
            </SkeletonFormItem>
          </Col>
          <Col span={3}>
            <SkeletonFormItem label="Type">
              <PromoTypeSelector onChange={this.onChangePromoType} value={filter.type} />
            </SkeletonFormItem>
          </Col>
          <Col span={3}>
            <SkeletonFormItem label="Status">
              <PromoStausSelector onChange={this.onChangePromoStatus} value={statusValue} />
            </SkeletonFormItem>
          </Col>
          <Col span={3} className="pr-0">
            <Link to={ERoutesPrivate.PromoCreate}>
              <Button block type="primary" title="Create promo" className="mt-4">
                Create promo
              </Button>
            </Link>
          </Col>
        </Row>
        <PromoTable config={tablePromoConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IPromoCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter, pageIndex: 1 };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    const name = text === '' ? undefined : text;

    this.setFilter({ name });
  };

  onChangeCriteria = (criteria?: EPromoCriteriaType) => {
    this.setFilter({ criteria });
  };

  onChangeBonusType = (bonusType?: EPromoBonusType) => {
    this.setFilter({ bonusType });
  };

  onChangePromoType = (type?: EPromoType) => {
    this.setFilter({ type });
  };

  onChangePromoStatus = (status?: EPromoStatusText) => {
    const isEnable = status === undefined ? undefined : status === EPromoStatusText.Enable;

    this.setFilter({ isEnable });
  };
}

export const PromoTablePage = withRouter(PromoPageComponent);
