import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesPublic } from 'common/models/routesModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { login } from 'app/store/reducers/auth.reducer';
import { IAuthData } from 'entities/Auth/Auth.models';

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authUserLoading, authModelError, authModelParams } = useAppSelector((state) => state.auth);
  const { fields } = useFormMapper(['email', 'password'], null, authModelParams, authModelError);

  const onLogin = (values: any) => {
    const { email, password } = values;
    const params: IAuthData = {
      email,
      password,
    };

    dispatch(login(params));
  };

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Log in
        </h1>
      </Row>
      <Form onFinish={onLogin} fields={fields} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
          ]}
          name="email"
          label="Email"
        >
          <Input name="email" type="email" placeholder="Email" disabled={authUserLoading} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required',
            },
          ]}
          name="password"
          label="Password"
        >
          <Input.Password type="password" placeholder="Password" disabled={authUserLoading} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Button htmlType="submit" block type="primary" title="Log in" disabled={authUserLoading} loading={authUserLoading}>
                Log in
              </Button>
            </Col>
            <Link
              to={ERoutesPublic.PasswordForgot}
              className="font-family__default fs-xs basic__link ellipsis"
              title="Forgot password?"
            >
              Forgot password?
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
