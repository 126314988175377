import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import { menuItemTransport } from 'entities/MenuItem/MenuItem.transport';
import {
  EMenuItemSuccessMessage,
  IMenuItemCreateData,
  IMenuItemModel,
  IMenuItemUpdateData,
  IMenuItemCollectionFilter,
  IMenuItemCollection,
  IMenuItemParamsModel,
} from 'entities/MenuItem/MenuItem.models';

export interface IState {
  menuItemModelLoading: boolean;
  menuItemModel: IMenuItemModel | null;
  menuItemModelError: IError | null;
  menuItemModelParams: IMenuItemParamsModel | null;
  menuItemCollectionLoading: boolean;
  menuItemCollection: IMenuItemCollection | null;
  menuItemCollectionError: IError | null;
  menuItemCollectionParams: IMenuItemCollectionFilter | null;
}

const initialState: IState = {
  menuItemModelLoading: false,
  menuItemModel: null,
  menuItemModelError: null,
  menuItemModelParams: null,
  menuItemCollectionLoading: false,
  menuItemCollection: null,
  menuItemCollectionError: null,
  menuItemCollectionParams: null,
};

export const menuItemSlice = createSlice({
  name: 'menuItem',
  initialState,
  reducers: {
    setMenuItemModelLoading(state, action: PayloadAction<boolean>) {
      state.menuItemModelLoading = action.payload;
    },
    setMenuItemModel(state, action: PayloadAction<IMenuItemModel | null>) {
      state.menuItemModel = action.payload;
    },
    setMenuItemModelError(state, action: PayloadAction<IError | null>) {
      state.menuItemModelError = action.payload;
    },
    setMenuItemModelParams(state, action: PayloadAction<IMenuItemParamsModel | null>) {
      state.menuItemModelParams = action.payload;
    },
    setMenuItemCollectionLoading(state, action: PayloadAction<boolean>) {
      state.menuItemCollectionLoading = action.payload;
    },
    setMenuItemCollection(state, action: PayloadAction<IMenuItemCollection | null>) {
      state.menuItemCollection = action.payload;
    },
    setMenuItemCollectionError(state, action: PayloadAction<IError | null>) {
      state.menuItemCollectionError = action.payload;
    },
    setMenuItemCollectionParams(state, action: PayloadAction<IMenuItemCollectionFilter | null>) {
      state.menuItemCollectionParams = action.payload;
    },
  },
});

export const {
  setMenuItemModelLoading,
  setMenuItemModel,
  setMenuItemModelError,
  setMenuItemModelParams,
  setMenuItemCollectionLoading,
  setMenuItemCollection,
  setMenuItemCollectionError,
  setMenuItemCollectionParams,
} = menuItemSlice.actions;
export default menuItemSlice.reducer;

export const getMenuItemModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuItemModelLoading(true));

    try {
      const menuItemModel = await menuItemTransport.get(id);

      dispatch(setMenuItemModel(menuItemModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuItemModelError(_error));
    } finally {
      dispatch(setMenuItemModelLoading(false));
    }
  }

  return thunk;
};

export const addMenuItemModel = (params: IMenuItemCreateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuItemModelLoading(true));

    try {
      const menuItemModel = await menuItemTransport.add(params);

      dispatch(setMenuItemModel(menuItemModel));

      message.success(EMenuItemSuccessMessage.Add);
      history.push(`${ERoutesPrivate.Menu}?tab=items`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuItemModelError(_error));
    } finally {
      dispatch(setMenuItemModelLoading(false));
    }
  }

  return thunk;
};

export const updateMenuItemModel = (params: IMenuItemUpdateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuItemModelLoading(true));

    try {
      const menuItemModel = await menuItemTransport.update(params);

      dispatch(setMenuItemModel(menuItemModel));

      message.success(EMenuItemSuccessMessage.Edit);
      history.push(`${ERoutesPrivate.Menu}?tab=items`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuItemModelError(_error));
    } finally {
      dispatch(setMenuItemModelLoading(false));
    }
  }

  return thunk;
};

export const deleteMenuItemModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuItemModelLoading(true));

    try {
      await menuItemTransport.deleteSoft(id);

      dispatch(setMenuItemModel(null));

      message.success(EMenuItemSuccessMessage.Delete);
      history.push(`${ERoutesPrivate.Menu}?tab=items`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuItemModelError(_error));
    } finally {
      dispatch(setMenuItemModelLoading(false));
    }
  }

  return thunk;
};

export const getMenuItemCollection = (filter?: IMenuItemCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuItemCollectionLoading(true));

    try {
      const menuItemCollection = await menuItemTransport.getCollection(filter);

      dispatch(setMenuItemCollection(menuItemCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuItemCollectionError(_error));
    } finally {
      dispatch(setMenuItemCollectionLoading(false));
    }
  }

  return thunk;
};
