import React, { useState } from 'react';
import { Button, Col, Form, Input, notification, Radio, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/es/radio';
import { TimePickerCommon } from 'common/components/DateTime/TimePickerCommon';
import { formRules, requireText } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { MapPlacePicker } from 'common/components/Map/MapPlacePicker';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppSelector } from 'app/store/store.hooks';
import { EStoreWorkSchedule, IStoreCreateData, IStoreUpdateData } from 'entities/Store/Store.models';
import { UserSelector } from 'entities/User/components/Selector/UserSelector';
import { StoreStatusSelector } from 'entities/Store/components/Selector/StoreStatusSelector';
import { EUserRole } from 'entities/User/User.models';
import { AssetSelector } from 'entities/Asset/components/Selector/AssetSelector';
interface IComponentProps {
  createStore?: (data: IStoreCreateData) => void;
  updateStore?: (data: IStoreUpdateData) => void;
  typeForm: ECommonFormType;
}

export const StoreForm: React.FC<IComponentProps> = ({ createStore, updateStore, typeForm }) => {
  const { storeModel, storeModelLoading, storeModelParams, storeModelError } = useAppSelector((state) => state.store);
  const [workScheduleValue, setWorkSchedule] = useState<EStoreWorkSchedule>(
    (storeModel?.schedule as EStoreWorkSchedule) || EStoreWorkSchedule.FullTime
  );
  const isCreateForm = typeForm === ECommonFormType.Add;
  const submitButtonText = isCreateForm ? 'Add store' : 'Save';
  const isRequiredFranchiseeSelector = !isCreateForm && !!storeModel?.franchisee;
  const isRequiredAssetSelector = !isCreateForm && !!storeModel?.asset;
  const { fields } = useFormMapper(
    ['email', 'password', 'storeCode', 'storeName', 'phone', 'franchisee', 'openTime', 'closeTime', 'address', 'asset', 'status'],
    storeModel,
    storeModelParams?.data,
    storeModelError
  );

  const saveStore = (values: any) => {
    const { email, storeName, phone, password, openTime, closeTime, storeCode, franchisee, address, status, asset } = values;

    const vals: IStoreUpdateData = {
      storeName,
      password,
      franchisee,
      asset,
      openTime,
      closeTime,
      schedule: workScheduleValue,
      phone,
      email,
      address,
    };

    if (franchisee) {
      vals.franchisee = franchisee;
    }
    if (asset) {
      vals.asset = asset;
    }

    isCreateForm ? createStore && createStore({ ...vals, storeCode } as IStoreCreateData) : notification.destroy();
    updateStore && updateStore({ ...vals, status });
  };

  const changeWorkSchedule = (e: RadioChangeEvent) => {
    setWorkSchedule(e.target.value);
  };

  const openNotification = () => {
    if (!isCreateForm) {
      notification.destroy();
      notification.warning({
        message: `Warning!`,
        description: "You are about to reset this store's current password",
        placement: 'topRight',
        duration: null,
      });
    }
  };

  return (
    <>
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <Form className="mt-5" onFinish={saveStore} fields={fields} layout="vertical" autoComplete="off">
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <Form.Item
              rules={[formRules.required, { type: 'email', message: 'Please enter correct email' }]}
              name="email"
              label="Store email"
            >
              <Input type="email" autoComplete="off" name="email" placeholder="Email" disabled={storeModelLoading} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: isCreateForm,
                  message: requireText,
                },
                {
                  min: 8,
                  message: 'Password must be longer than or equal 8 characters',
                },
                {
                  max: 16,
                  message: 'Password must be shorter than or equal 16 characters',
                },
              ]}
              name="password"
              label="Store password"
            >
              <Input.Password
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                disabled={storeModelLoading}
                onFocus={openNotification}
              />
            </Form.Item>
          </Col>
          {!isCreateForm && (
            <Col span={12}>
              <Form.Item rules={[formRules.required]} name="status" label="Store status">
                <StoreStatusSelector />
              </Form.Item>
            </Col>
          )}
          {isCreateForm && (
            <Col span={12}>
              <Form.Item
                rules={[
                  formRules.required,
                  {
                    min: 3,
                    max: 4,
                    message: 'Store code should be equal to 3 or 4 digits',
                  },
                ]}
                name="storeCode"
                label="Store code"
              >
                <Input name="code" type="text" placeholder="Store code" disabled={storeModelLoading} />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              rules={[
                formRules.required,
                {
                  min: 2,
                  message: 'Store name must be longer than or equal to 2 characters',
                },
                {
                  max: 20,
                  message: 'Store name must be shorter than or equal to 20 characters',
                },
              ]}
              name="storeName"
              label="Store name"
            >
              <Input name="storeName" type="text" placeholder="Store name" disabled={storeModelLoading} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item rules={[formRules.required, formRules.correctPhone]} name="phone" label="Contact phone">
              <Input name="phone" type="tel" placeholder="+63 9XX XXX XXXX" disabled={storeModelLoading} />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item rules={[formRules.required]} name="address" label="Please select store location">
            <MapPlacePicker />
          </Form.Item>
        </Col>

        <Row className="mt-8 mb-5" align="middle">
          <span className="basic__text_title" title="Management">
            Management
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: isRequiredFranchiseeSelector,
                  message: requireText,
                },
              ]}
              name="franchisee"
              label="Franchisee"
            >
              <UserSelector placeholder="Enter franchisee name" role={EUserRole.Franchisee} disabled={storeModelLoading} />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24} className="mt-5">
          <span className="basic__text_title" title="Menu asset">
            Menu asset
          </span>
        </Col>
        <Row gutter={[28, 0]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: isRequiredAssetSelector,
                  message: requireText,
                },
              ]}
              label="Please select asset"
              name="asset"
            >
              <AssetSelector placeholder="Enter asset name" disabled={storeModelLoading} />
            </Form.Item>
          </Col>
          <Col span={12} className="mt-9">
            <Link to={ERoutesPrivate.AssetCreate} target="_blank">
              <Typography.Text className="fs-xs color-blue">Create custom asset</Typography.Text>
            </Link>
          </Col>
        </Row>
        <Col span={24} className="mt-5 mb-5">
          <span className="basic__text_title" title="Working hours">
            Working hours
          </span>
        </Col>
        <Row gutter={[28, 12]}>
          <Col span={24}>
            <Radio.Group
              onChange={changeWorkSchedule}
              disabled={storeModelLoading}
              defaultValue={EStoreWorkSchedule.FullTime}
              value={workScheduleValue}
            >
              <Radio value={EStoreWorkSchedule.FullTime}>{EStoreWorkSchedule.FullTime}</Radio>
              <Radio value={EStoreWorkSchedule.MonSat}>{EStoreWorkSchedule.MonSat}</Radio>
              <Radio value={EStoreWorkSchedule.MonSun}>{EStoreWorkSchedule.MonSun}</Radio>
            </Radio.Group>
          </Col>
          {workScheduleValue !== EStoreWorkSchedule.FullTime && (
            <>
              <Col span={6}>
                <Form.Item rules={[formRules.required]} name="openTime" label="Opens at">
                  <TimePickerCommon />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item rules={[formRules.required]} name="closeTime" label="Closes at">
                  <TimePickerCommon />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Col span={10} className="mt-4">
          <Row gutter={[28, 0]}>
            <Col span={12}>
              <Form.Item className="mb-0">
                <Button
                  htmlType="submit"
                  type="primary"
                  block
                  disabled={storeModelLoading}
                  loading={storeModelLoading}
                  title={submitButtonText}
                >
                  {submitButtonText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Link to={ERoutesPrivate.Stores}>
                <Button
                  block
                  disabled={storeModelLoading}
                  loading={storeModelLoading}
                  title="Cancel"
                  onClick={notification.destroy}
                >
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Form>
    </>
  );
};
