import React, { ChangeEvent } from 'react';
import { Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CustomerTable } from 'entities/Customer/components/CustomerTable';
import { ECustomerStatus, ICustomerCollectionFilter, tableCustomerConfig } from 'entities/Customer/Customer.models';
import { CustomerStatusSelector } from 'entities/Customer/components/Selector/CustomerStatusSelector';

interface IComponentState {
  filter: ICustomerCollectionFilter;
}

type AllProps = RouteComponentProps;

class CustomerTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      search: undefined,
      statuses: undefined,
    },
  };

  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ICustomerCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  autocompleteSearch = (text: string) => {
    const search = text === '' ? undefined : text;
    this.setFilter({ search });
  };

  setFilter = (partialFilter: Partial<ICustomerCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter, pageIndex: 1 };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (status?: ECustomerStatus) => {
    const statuses = status ? [status] : [];

    this.setFilter({ statuses });
  };

  render() {
    const { filter } = this.state;
    const statusValue = filter.statuses?.length ? filter.statuses[0] : undefined;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" align="middle" gutter={[64, 0]}>
          <Col span={8}>
            <Input.Search
              placeholder="Search by name"
              defaultValue={filter.search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <CustomerStatusSelector onChangeStatus={this.onChangeStatus} value={statusValue} />
          </Col>
        </Row>
        <CustomerTable config={tableCustomerConfig} filter={filter} />
      </CommonLayout>
    );
  }
}

export const CustomerTablePage = withRouter(CustomerTablePageComponent);
