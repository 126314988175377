import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import {
  addMenuItemModel,
  deleteMenuItemModel,
  getMenuItemModel,
  setMenuItemModel,
  updateMenuItemModel,
} from 'app/store/reducers/menuItem.reducer';
import { IMenuItemCreateData, IMenuItemUpdateData } from 'entities/MenuItem/MenuItem.models';
import { MenuItemForm } from 'entities/MenuItem/components/Form/MenuItemForm';
import { MenuItemFormSkeleton } from 'entities/MenuItem/components/Skeleton/MenuItemFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const MenuItemPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { menuItemModel, menuItemModelLoading } = useAppSelector((state) => state.menuItem);
  const { params } = match;
  const { id } = params;
  const menuItemName = menuItemModel?.name;
  const isEdit = !!id;

  const updateMenuItem = (data: IMenuItemUpdateData) => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(updateMenuItemModel({ ...data, id }));
    }
  };

  const createMenuItem = (data: IMenuItemCreateData) => {
    dispatch(addMenuItemModel(data));
  };

  const deleteMenuItem = () => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(deleteMenuItemModel(id));
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getMenuItemModel(id));
    }

    return () => dispatch(setMenuItemModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Items}`}>Items list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !menuItemModelLoading ? (
                menuItemName
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Add new item'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!menuItemModelLoading || !isEdit ? (
          <MenuItemForm
            createMenuItem={createMenuItem}
            updateMenuItem={updateMenuItem}
            deleteMenuItem={deleteMenuItem}
            menuItemId={id}
          />
        ) : (
          <MenuItemFormSkeleton isEdit={isEdit} />
        )}
      </Col>
    </CommonLayout>
  );
};
