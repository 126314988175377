import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { StoreTable } from 'entities/Store/components/StoreTable';
import { EStoreStatus, IStoreCollectionFilter, tableStoreConfig } from 'entities/Store/Store.models';
import { StoreStatusSelector } from 'entities/Store/components/Selector/StoreStatusSelector';
import { UserSelector } from 'entities/User/components/Selector/UserSelector';
import { EUserRole, IUserModel } from 'entities/User/User.models';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IStoreCollectionFilter;
}

class StorePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      search: undefined,
      statuses: undefined,
      franchisee: undefined,
    },
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IStoreCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const statusValue = filter.statuses?.length ? filter.statuses[0] : undefined;
    const franchisee = filter?.franchisee;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row justify="space-between" align="middle">
              <Col span={8}>
                <Input.Search
                  placeholder="Search by name / code / short ID"
                  defaultValue={filter.search}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={8}>
                <Row className="flex-noWrap" justify="space-between" align="middle">
                  <span className="mr-4">Franchisee</span>
                  <UserSelector
                    onChange={this.onChangeSelector}
                    value={franchisee}
                    placeholder="Enter franchisee name"
                    role={EUserRole.Franchisee}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row className="flex-noWrap" justify="space-between" align="middle">
                  <span className="mr-4">Status</span>
                  <StoreStatusSelector onChange={this.onChangeStatus} value={statusValue} />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Link to={ERoutesPrivate.StoresCreate}>
              <Button block type="primary" title="Add new store">
                Add new store
              </Button>
            </Link>
          </Col>
        </Row>
        <StoreTable config={tableStoreConfig} filter={filter} />
      </CommonLayout>
    );
  }

  onChangeSelector = (item?: IUserModel) => {
    const { filter } = this.state;
    const franchisee = item ? { id: item?.id, lastName: item?.lastName, firstName: item?.firstName } : undefined;

    this.setState({ filter: { ...filter, franchisee } }, () => this.setFilter({ franchisee }));
  };

  setFilter = (partialFilter: Partial<IStoreCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter, pageIndex: 1 };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    const search = text === '' ? undefined : text;

    this.setFilter({ search });
  };

  onChangeStatus = (status?: EStoreStatus) => {
    const statuses = status ? [status] : [];

    this.setFilter({ statuses });
  };
}

export const StoreTablePage = withRouter(StorePageComponent);
