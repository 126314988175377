// import { BaseHttpTransport } from '@axmit/transport';
// import axios, { AxiosStatic } from 'axios';
// import { IQRModel } from 'entities/QR/QR.models';

// const basePath = 'qr-codes';

// class QRTransport extends BaseHttpTransport<AxiosStatic, IQRModel> {
//   public update = (params: any): Promise<IQRModel> =>
//     axios.put(`${basePath}/${params.path?.id}`, {
//       ...params.data
//     });
// }

// export const qrTransport = new QRTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IQRCollection, IQRCollectionFilter, IQRCreateData, IQRModel } from 'entities/QR/QR.models';

const basePath = 'qr-codes';

export const qrTransport = {
  get: (id: string): Promise<IQRModel> => axios.get(`${basePath}/${id}`),
  add: (params: IQRCreateData): Promise<IQRModel> => axios.post(`${basePath}`, params),
  update: (params: any): Promise<IQRModel> =>
    axios.put(`${basePath}/${params.path?.id}`, {
      ...params.data,
    }),
  delete: (id: string): Promise<void> => axios.delete(`${basePath}/${id}`),
  getCollection: (filter?: IQRCollectionFilter): Promise<IQRCollection> => axios.get(`${basePath}${objectToQuery(filter)}`),
};
