import React, { useEffect } from 'react';
import { Button, Form, Input, Row } from 'antd';
import queryString from 'query-string';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ERoutesPublic } from 'common/models/routesModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { checkPasswordRestoreToken, restorePassword } from 'app/store/reducers/auth.reducer';
import { IAuthPasswordRestoreParams } from 'entities/Auth/Auth.models';

export const PasswordRestorePage: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useAppDispatch();
  const {
    authTokenCheckError,
    authTokenCheckLoading,
    authPasswordRestoreLoading,
    authPasswordRestoreError,
    authPasswordRestoreParams,
  } = useAppSelector((state) => state.auth);
  const isTokenNotExpired = !authTokenCheckLoading && !authTokenCheckError;
  const token = (queryString.parse(history.location.search).token as string) || '';
  const { fields } = useFormMapper(['password'], null, authPasswordRestoreParams, authPasswordRestoreError);

  useEffect(() => {
    dispatch(checkPasswordRestoreToken({ token }));
  }, [token]);

  const newPassword = (values: any) => {
    const { password } = values;
    const params: IAuthPasswordRestoreParams = {
      password,
      token,
    };

    dispatch(restorePassword(params));
  };

  if (authTokenCheckError) {
    return <LoadingSpin />;
  }

  return isTokenNotExpired ? (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Create new password">
          Create new password
        </h1>
      </Row>
      <Form onFinish={newPassword} fields={fields} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required',
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters',
            },
            {
              max: 16,
              message: 'Password must be shorter than or equal 16 characters',
            },
          ]}
          name="password"
          label="New password"
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={authPasswordRestoreLoading}
            loading={authPasswordRestoreLoading}
            title="Submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  ) : (
    <Row justify="center" align="middle">
      <span className="t-align-c fs-sm font-weight-5 font-family__default">The link to set a new password has expired.</span>
      <span className="t-align-c">
        Please click <Link to={ERoutesPublic.PasswordForgot}>here</Link> to request a new one.
      </span>
    </Row>
  );
};
