import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import {
  ERatingSuccessMessage,
  IRatingCollection,
  IRatingCollectionFilter,
  IRatingModel,
  IRatingParamsModel,
  IRatingUpdateData,
} from 'entities/Rating/Rating.models';
import { ratingTransport } from 'entities/Rating/Rating.transport';

export interface IState {
  ratingModelLoading: boolean;
  ratingModel: IRatingModel | null;
  ratingModelError: IError | null;
  ratingModelParams: IRatingParamsModel | null;
  ratingCollectionLoading: boolean;
  ratingCollection: IRatingCollection | null;
  ratingCollectionError: IError | null;
  ratingCollectionParams: IRatingCollectionFilter | null;
}

const initialState: IState = {
  ratingModelLoading: false,
  ratingModel: null,
  ratingModelError: null,
  ratingModelParams: null,
  ratingCollectionLoading: false,
  ratingCollection: null,
  ratingCollectionError: null,
  ratingCollectionParams: null,
};

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    setRatingModelLoading(state, action: PayloadAction<boolean>) {
      state.ratingModelLoading = action.payload;
    },
    setRatingModel(state, action: PayloadAction<IRatingModel | null>) {
      state.ratingModel = action.payload;
    },
    setRatingModelError(state, action: PayloadAction<IError | null>) {
      state.ratingModelError = action.payload;
    },
    setRatingModelParams(state, action: PayloadAction<IRatingParamsModel | null>) {
      state.ratingModelParams = action.payload;
    },
    setRatingCollectionLoading(state, action: PayloadAction<boolean>) {
      state.ratingCollectionLoading = action.payload;
    },
    setRatingCollection(state, action: PayloadAction<IRatingCollection | null>) {
      state.ratingCollection = action.payload;
    },
    setRatingCollectionError(state, action: PayloadAction<IError | null>) {
      state.ratingCollectionError = action.payload;
    },
    setRatingCollectionParams(state, action: PayloadAction<IRatingCollectionFilter | null>) {
      state.ratingCollectionParams = action.payload;
    },
  },
});

export const {
  setRatingModelLoading,
  setRatingModel,
  setRatingModelError,
  setRatingModelParams,
  setRatingCollectionLoading,
  setRatingCollection,
  setRatingCollectionError,
  setRatingCollectionParams,
} = ratingSlice.actions;
export default ratingSlice.reducer;

export const getRatingModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setRatingModelLoading(true));

    try {
      const ratingModel = await ratingTransport.get(id);

      dispatch(setRatingModel(ratingModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setRatingModelError(_error));
    } finally {
      dispatch(setRatingModelLoading(false));
    }
  }

  return thunk;
};

export const updateRatingModel = (params: IRatingUpdateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setRatingModelLoading(true));

    try {
      const ratingModel = await ratingTransport.update(params);

      dispatch(setRatingModel(ratingModel));

      message.success(ERatingSuccessMessage.Edit);
      history.push(ERoutesPrivate.Rating);
    } catch (error) {
      const _error = error as IError;

      dispatch(setRatingModelError(_error));
    } finally {
      dispatch(setRatingModelLoading(false));
    }
  }

  return thunk;
};

export const getRatingCollection = (filter?: IRatingCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setRatingCollectionLoading(true));

    try {
      const ratingCollection = await ratingTransport.getCollection(filter);

      dispatch(setRatingCollection(ratingCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setRatingCollectionError(_error));
    } finally {
      dispatch(setRatingCollectionLoading(false));
    }
  }

  return thunk;
};
