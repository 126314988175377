import React, { useState } from 'react';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { formRules } from 'common/const/common.const';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppSelector } from 'app/store/store.hooks';
import { EUserRole, EUserRoleText, EUserStatus, IUserInviteData, IUserUpdateData } from 'entities/User/User.models';
import { UserMultipleSelector } from 'entities/User/components/Selector/UserMultiSelector';

interface IComponentProps {
  inviteUser: (data: IUserInviteData) => void;
  updateUser: (data: IUserUpdateData) => void;
  reInviteUser: () => void;
  blockUser: () => void;
  deleteUser: () => void;
  typeForm: ECommonFormType;
}

type AllProps = IComponentProps;

export const UserForm: React.FC<AllProps> = (props) => {
  const { userModel, userModelLoading, userModelParams, userModelError } = useAppSelector((state) => state.user);
  const { typeForm, inviteUser, updateUser, reInviteUser, blockUser, deleteUser } = props;
  const [userRole, setUserRole] = useState<string>();
  const isUpdateForm = typeForm === ECommonFormType.Update;
  const { fields } = useFormMapper(
    ['role', 'firstName', 'lastName', 'phone', 'email', 'franchisees'],
    userModel,
    userModelParams?.data,
    userModelError
  );

  const isPending = userModel?.status === EUserStatus.Pending;
  const isBlocked = userModel?.status === EUserStatus.Blocked;
  const isSalesOps = userRole === EUserRole.SalesOps || userModel?.role === EUserRole.SalesOps;
  const blockButtonText = isPending ? 'Revoke invitation' : isBlocked ? 'Unblock' : 'Block';
  const submitButtonText = isUpdateForm ? 'Save' : 'Invite';
  const stores = userModel?.stores;

  const addUser = (values: any) => {
    const { email, role, firstName, lastName, phone, franchisees } = values;
    const vals: IUserInviteData = {
      email,
      role,
      firstName,
      lastName,
      phone,
    };

    if (userRole === EUserRole.SalesOps) {
      vals.franchisees = franchisees;
    }

    inviteUser(vals);
  };

  const editUser = (values: any) => {
    const { firstName, lastName, phone, franchisees } = values;
    const vals: IUserUpdateData = {
      firstName,
      lastName,
      phone,
    };

    if (userModel?.role === EUserRole.SalesOps) {
      vals.franchisees = franchisees;
    }

    updateUser(vals);
  };

  return (
    <Form onFinish={isUpdateForm ? editUser : addUser} fields={fields} layout="vertical">
      {!isUpdateForm && (
        <>
          <span className="basic__text_title" title="Who do you want to invite?">
            Who do you want to invite?
          </span>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Role is required',
              },
            ]}
            name="role"
            className="mt-5"
          >
            <Radio.Group onChange={(e) => setUserRole(e.target.value)} defaultValue={EUserRole.Franchisee}>
              <Radio value={EUserRole.Franchisee}>{EUserRoleText.Franchisee}</Radio>
              <Radio value={EUserRole.Franchisor}>{EUserRoleText.Franchisor}</Radio>
              <Radio value={EUserRole.SalesOps}>{EUserRoleText.SalesOps}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      )}
      <Row className="mb-5" align="middle">
        <span className="basic__text_title" title="Personal info">
          Personal info
        </span>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'First name is required',
              },
              {
                min: 2,
                message: 'First name must be longer than or equal 2 characters',
              },
              {
                max: 32,
                message: 'First name must be shorter than or equal 32 characters',
              },
            ]}
            name="firstName"
            label="First name"
          >
            <Input name="firstName" type="text" placeholder="First name" disabled={userModelLoading} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Last name is required',
              },
              {
                min: 2,
                message: 'Last name must be longer than or equal 2 characters',
              },
              {
                max: 32,
                message: 'Last name must be shorter than or equal 32 characters',
              },
            ]}
            name="lastName"
            label="Last name"
          >
            <Input name="lastName" type="text" placeholder="Last name" disabled={userModelLoading} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Phone is required',
              },
              formRules.correctPhone,
            ]}
            name="phone"
            label="Phone number"
          >
            <Input name="Phone" type="tel" placeholder="+63 9XX XXX XXXX" disabled={userModelLoading} />
          </Form.Item>
        </Col>
        {isSalesOps && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Franchisee is required',
                },
              ]}
              name="franchisees"
              label="Franchisee"
            >
              <UserMultipleSelector />
            </Form.Item>
          </Col>
        )}
      </Row>
      {isUpdateForm && !!stores?.length && (
        <>
          <span className="basic__text_title" title="Stores">
            Stores
          </span>
          <Row align="middle">
            {stores.map((store, index) => (
              <li key={store.id} className="mr-2">
                <Link to={`${ERoutesPrivate.Stores}/${store.shortStoreId}`} className="basic__link">
                  {store.storeName}
                </Link>
                {index !== stores.length - 1 && ', '}
              </li>
            ))}
          </Row>
        </>
      )}

      {isUpdateForm ? (
        <>
          {isPending && (
            <>
              <Col span={24} className="mt-5">
                <span className="basic__text_title" title="Invitation">
                  Invitation
                </span>
              </Col>
              <Col span={24} className="mt-5 mb-5">
                <span
                  className="fs-xxs font-family__default"
                  title="The invitation to join the system will be sent to the specified email address."
                >
                  The invitation to join the system will be sent to the specified email address.
                </span>
              </Col>
            </>
          )}
          <Row className="mb-8 mt-5" gutter={[28, 0]}>
            <Col className="pb-2" span={24}>
              <span className="fs-xxs font-family__default" title="Email">
                Email
              </span>
            </Col>
            <Col span={12}>
              <Input type="text" name="email" placeholder="Email" disabled value={userModel?.email} title={userModel?.email} />
            </Col>
            {isPending && (
              <Col span={12}>
                <Button onClick={reInviteUser} disabled={userModelLoading} loading={userModelLoading} title="Resend invitation">
                  Resend invitation
                </Button>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <Row gutter={[28, 12]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
              ]}
              name="email"
              label="Email"
            >
              <Input type="email" name="email" placeholder="Email" disabled={userModelLoading} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={userModelLoading}
              loading={userModelLoading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={ERoutesPrivate.Users}>
              <Button disabled={userModelLoading} loading={userModelLoading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
        {isUpdateForm && (
          <Button
            danger
            disabled={userModelLoading}
            loading={userModelLoading}
            title={blockButtonText}
            onClick={isPending ? deleteUser : blockUser}
          >
            {blockButtonText}
          </Button>
        )}
      </Row>
    </Form>
  );
};
