import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { store } from 'app/store';
import { getStatisticCollection, setStatisticCollection } from 'app/store/reducers/statistic.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { EStatisticRange, IStatisticCollectionFilter, IStatisticModel } from 'entities/Statistic/Statistic.models';
import { StatisticStoreListItem } from 'entities/Statistic/components/StatisticStoreListItem';

interface IComponentProps {
  isSalesOps?: boolean;
  filter?: IStatisticCollectionFilter;
}

export const StatisticStoreList: React.FC<IComponentProps> = ({ isSalesOps, filter }) => {
  const dispatch = useAppDispatch();
  const { statisticCollection, statisticCollectionLoading } = useAppSelector((state) => state.statistic);

  const loadCollection = (params: IBaseFilterModel) => {
    if (isSalesOps) {
      store.dispatch(getStatisticCollection({ ...params, ...filter }));
    } else {
      store.dispatch(getStatisticCollection({ ...params, range: EStatisticRange.Today }));
    }
  };

  const renderListItem = (item: IStatisticModel) => {
    return <StatisticStoreListItem item={item} />;
  };

  const clearCollection = () => {
    dispatch(setStatisticCollection(null));
  };

  return (
    <InfiniteList
      elementId="basicLayout"
      filter={filter}
      collection={statisticCollection}
      loading={statisticCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      renderListItem={renderListItem}
    />
  );
};
