import React, { useEffect } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setUserModel, updatePassword } from 'app/store/reducers/user.reducer';

interface IComponentProps {
  isFranchisee?: boolean;
}

export const UserProfileForm: React.FunctionComponent<IComponentProps> = ({ isFranchisee = false }) => {
  const dispatch = useAppDispatch();
  const { authModel } = useAppSelector((state) => state.auth);
  const { userModelLoading, userModelError, userModelParams } = useAppSelector((state) => state.user);
  const userId = authModel?.access?.userId;

  const { fields } = useFormMapper(['oldPassword', 'password'], null, userModelParams?.data, userModelError);

  useEffect(() => {
    return () => dispatch(setUserModel(null));
  }, []);

  const changePassword = (values: any) => {
    if (userId) {
      dispatch(updatePassword({ path: { id: userId }, data: { oldPassword: values.oldPassword, password: values.password } }));
    }
  };

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className={`${isFranchisee ? 'franchisee__text_title' : 'basic__text_title'} fs-md`} title="Change password">
          Change password
        </h1>
      </Row>
      <Form onFinish={changePassword} fields={fields} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Old password is required',
            },
          ]}
          name="oldPassword"
          label="Old password"
        >
          <Input.Password name="oldPassword" type="password" disabled={userModelLoading} placeholder="Old password" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'New password is required',
            },
            {
              min: 8,
              message: 'New password must be at least 8 characters',
            },
            {
              max: 16,
              message: 'New password must be shorter than or equal 16 characters',
            },
          ]}
          name="password"
          label="New password"
        >
          <Input.Password name="newPassword" type="password" disabled={userModelLoading} placeholder="New password" />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button
            htmlType="submit"
            type="primary"
            className="button_default"
            disabled={userModelLoading}
            loading={userModelLoading}
            title="Change"
          >
            Change
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
