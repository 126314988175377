import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import {
  addPromoModel,
  deletePromoModel,
  getPromoModel,
  setPromoModel,
  updatePromoModel,
} from 'app/store/reducers/promo.reducer';
import { IPromoCreateData, IPromoUpdateData } from 'entities/Promo/Promo.models';
import { PromoCreateForm } from 'entities/Promo/components/Form/PromoCreateForm';
import { PromoEditForm } from 'entities/Promo/components/Form/PromoEditForm';
import { PromoFormSkeletonPage } from 'entities/Promo/components/Skeleton/PromoFormSkeletonPage';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const PromoPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { promoModel, promoModelLoading } = useAppSelector((state) => state.promo);
  const { params } = match;
  const { id } = params;
  const promoName = promoModel?.name;
  const isEdit = !!id;
  const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

  const addPromo = (data: IPromoCreateData) => {
    dispatch(addPromoModel(data));
  };

  const updatePromo = (data: IPromoUpdateData) => {
    dispatch(updatePromoModel(data));
  };

  const deletePromo = (id: string) => {
    dispatch(deletePromoModel(id));
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getPromoModel(id));
    }

    return () => dispatch(setPromoModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={16}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Promo}>Promo list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !promoModelLoading ? (
                promoName
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Create new promo'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!promoModelLoading ? (
          isEdit ? (
            <PromoEditForm updatePromo={updatePromo} deletePromo={deletePromo} />
          ) : (
            <PromoCreateForm createPromo={addPromo} />
          )
        ) : (
          <PromoFormSkeletonPage typeForm={typeForm} />
        )}
      </Col>
    </CommonLayout>
  );
};
