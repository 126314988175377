import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '@axmit/error-helper';
import { AppDispatch } from 'app/store';
import {
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticGlobalParams,
  IStatisticGlobalModel,
  IStatisticModel,
  IStatisticParams,
  IStatisticCSV,
  IStatisticSalesOpsParams,
} from 'entities/Statistic/Statistic.models';
import { statisticTransport } from 'entities/Statistic/Statistic.transport';

export interface IState {
  statisticModel: IStatisticModel | null;
  statisticGlobalModel: IStatisticGlobalModel | null;
  statisticSalesOpsModel: IStatisticGlobalModel | null;
  statisticCollectionLoading: boolean;
  statisticCollection: IStatisticCollection | null;
  statisticCollectionError: IError | null;
  statisticCollectionParams: IStatisticCollectionFilter | null;
  CSV: IStatisticCSV | null;
}

const initialState: IState = {
  statisticModel: null,
  statisticGlobalModel: null,
  statisticSalesOpsModel: null,
  statisticCollectionLoading: false,
  statisticCollection: null,
  statisticCollectionError: null,
  statisticCollectionParams: null,
  CSV: null,
};

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    setStatisticModel(state, action: PayloadAction<IStatisticModel | null>) {
      state.statisticModel = action.payload;
    },
    setStatisticGlobalModel(state, action: PayloadAction<IStatisticGlobalModel | null>) {
      state.statisticGlobalModel = action.payload;
    },
    setStatisticSalesOpsModel(state, action: PayloadAction<IStatisticGlobalModel | null>) {
      state.statisticSalesOpsModel = action.payload;
    },
    setStatisticCollectionLoading(state, action: PayloadAction<boolean>) {
      state.statisticCollectionLoading = action.payload;
    },
    setStatisticCollection(state, action: PayloadAction<IStatisticCollection | null>) {
      state.statisticCollection = action.payload;
    },
    setStatisticCollectionError(state, action: PayloadAction<IError | null>) {
      state.statisticCollectionError = action.payload;
    },
    setStatisticCollectionParams(state, action: PayloadAction<IStatisticCollectionFilter | null>) {
      state.statisticCollectionParams = action.payload;
    },
  },
});

export const {
  setStatisticModel,
  setStatisticGlobalModel,
  setStatisticSalesOpsModel,
  setStatisticCollectionLoading,
  setStatisticCollection,
  setStatisticCollectionError,
  setStatisticCollectionParams,
} = statisticSlice.actions;
export default statisticSlice.reducer;

export const getStatisticModel = (params: IStatisticParams) => {
  async function thunk(dispatch: AppDispatch) {
    try {
      const statisticModel = await statisticTransport.getModel(params);

      dispatch(setStatisticModel(statisticModel));
    } catch (_error) {
      // I want application to not crush, but don't care about the message
    }
  }

  return thunk;
};

export const getStatisticGlobalModel = (params: IStatisticGlobalParams) => {
  async function thunk(dispatch: AppDispatch) {
    try {
      const statisticModel = await statisticTransport.getGlobalModel(params);

      dispatch(setStatisticGlobalModel(statisticModel));
    } catch (_error) {
      // I want application to not crush, but don't care about the message
    }
  }

  return thunk;
};

export const getStatisticSalesOpsModel = (params: IStatisticSalesOpsParams) => {
  async function thunk(dispatch: AppDispatch) {
    try {
      const statisticModel = await statisticTransport.getSalesOpsModel(params);

      dispatch(setStatisticSalesOpsModel(statisticModel));
    } catch (_error) {
      // I want application to not crush, but don't care about the message
    }
  }

  return thunk;
};

export const getStatisticCollection = (filter?: IStatisticCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStatisticCollectionLoading(true));

    try {
      const statisticCollection = await statisticTransport.getCollection(filter);

      dispatch(setStatisticCollection(statisticCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setStatisticCollectionError(_error));
    } finally {
      dispatch(setStatisticCollectionLoading(false));
    }
  }

  return thunk;
};

export const getStatisticCSV = () => {
  async function thunk() {
    try {
      const csvModel = await statisticTransport.getStatisticCSV();

      const file = new Blob([csvModel], { type: 'application/zip' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (_error) {
      // I want application to not crush, but don't care about the message
    }
  }

  return thunk;
};
