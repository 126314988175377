import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPromoCustomerSelectType, EPromoCustomerSelectTypeText } from 'entities/Promo/Promo.models';

interface IComponentProps {
  onChange?: (value: EPromoCustomerSelectType) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PromoCustomerTypeSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPromoCustomerSelectType) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue } = this.props;

    return (
      <Select
        className="width-full"
        placeholder="Select customer type"
        value={value}
        defaultActiveFirstOption={true}
        defaultValue={defaultValue}
        onChange={(value: EPromoCustomerSelectType) => this.handleChange(value)}
      >
        <Select.Option
          key={EPromoCustomerSelectType.All}
          value={EPromoCustomerSelectType.All}
          label={EPromoCustomerSelectTypeText.All}
        >
          {EPromoCustomerSelectTypeText.All}
        </Select.Option>
        <Select.Option
          key={EPromoCustomerSelectType.New}
          value={EPromoCustomerSelectType.New}
          label={EPromoCustomerSelectTypeText.New}
        >
          {EPromoCustomerSelectTypeText.New}
        </Select.Option>
        <Select.Option
          key={EPromoCustomerSelectType.Specific}
          value={EPromoCustomerSelectType.Specific}
          label={EPromoCustomerSelectTypeText.Specific}
        >
          {EPromoCustomerSelectTypeText.Specific}
        </Select.Option>
      </Select>
    );
  }
}
