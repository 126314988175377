import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getCustomerModel, setCustomerModel, updateCustomerStatus } from 'app/store/reducers/customer.reducer';
import { CustomerForm } from 'entities/Customer/components/Form/CustomerForm';
import { CustomerFormSkeleton } from 'entities/Customer/components/Skeleton/CustomerFormSkeleton';
import { ICustomerUpdateStatusParams } from 'entities/Customer/Customer.models';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const CustomerPage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { customerModel, customerModelLoading } = useAppSelector((state) => state.customer);
  const customerName = nameBuilder(customerModel?.firstName, customerModel?.lastName);
  const status = customerModel?.status;
  const title = (
    <>
      {customerName} (<span className="t-transform-c">{status}</span>)
    </>
  );

  const updateCustomer = (params: ICustomerUpdateStatusParams) => {
    dispatch(updateCustomerStatus(params));
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getCustomerModel(id));
    }

    return () => dispatch(setCustomerModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Customers}>Customer list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {!customerModelLoading ? title : <Skeleton.Input active style={{ width: '200px' }} size="small" />}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!customerModelLoading ? <CustomerForm updateCustomer={updateCustomer} /> : <CustomerFormSkeleton />}
      </Col>
    </CommonLayout>
  );
};
