import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { formRules } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addQrModel, deleteQrModel, updateQrModel } from 'app/store/reducers/qr.reducer';
import { MenuItemSelector } from 'entities/MenuItem/components/Selector/MenuItemSelector';
import { StoreSelector } from 'entities/Store/components/Selector/StoreSelector';
import { QRCodeBlock } from 'entities/QR/components/QRCodeBlock';
import { IMenuItemModel } from 'entities/MenuItem/MenuItem.models';
import { IStoreModel } from 'entities/Store/Store.models';
import { IQRCreateData, IQRUpdateData } from 'entities/QR/QR.models';

interface IComponentProps {
  qrId?: string;
}

interface IFormValues {
  menuItem: IMenuItemModel;
  name: string;
  store?: IStoreModel;
}

export const QRForm: React.FC<IComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const { qrModel, qrModelLoading, qrModelParams, qrModelError } = useAppSelector((state) => state.qr);
  const { qrId = '' } = props;
  const [selectedStoreId, setStoreId] = useState('');
  const [selectedMenuItemId, setMenuItemId] = useState<string>('');
  const [qrName, setQRName] = useState<string>('');
  const { fields, setField } = useFormMapper(['menuItem', 'name', 'store'], qrModel, qrModelParams, qrModelError);

  const isUpdateForm = !!qrId;

  const submit = ({ name }: IFormValues) => {
    const formData: IQRCreateData | IQRUpdateData = {
      name: name.trim(),
      menuItem: selectedMenuItemId,
    };

    if (selectedStoreId) {
      formData.store = selectedStoreId;
    }

    isUpdateForm ? dispatch(updateQrModel({ path: { id: qrId }, data: formData })) : dispatch(addQrModel(formData));
  };

  useEffect(() => {
    if (qrModel) {
      const { name, storeId, menuItemId } = qrModel;

      setQRName(name);
      setStoreId(storeId || '');
      setMenuItemId(menuItemId);
    }
  }, [qrModel]);

  useEffect(() => {
    // @ts-ignore-next-line
    if (qrModelError?.data?.code === 'error.qRCodeAlreadyExistException') {
      setField({ name: 'name', errors: ['Name already in use'] });
    }
  }, [qrModelError]);

  const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setQRName(e.currentTarget.value);
  };

  const deleteQR = () => {
    if (qrId) {
      dispatch(deleteQrModel(qrId));
    }
  };

  const handleStoreChange = useCallback(
    (store) => {
      setStoreId(store?.id);
      setField({ name: 'name', value: qrName });
      setField({ name: 'store', value: store });
      setField({ name: 'menuItem', value: '' });
    },
    [setField, qrName]
  );

  const handleStoreClear = () => {
    handleStoreChange(null);
  };

  const handleMenuItemChange = useCallback((menuItem) => setMenuItemId(menuItem?.id), []);

  return (
    <Form onFinish={submit} layout="vertical" fields={fields}>
      <Row gutter={28}>
        <Col span={12}>
          <Form.Item
            rules={[
              formRules.required,
              {
                min: 2,
                message: 'QR name must be longer than or equal 2 characters',
              },
              {
                max: 128,
                message: 'QR name must be shorter than or equal 128 characters',
              },
            ]}
            name="name"
            label="QR name"
          >
            <Input name="name" type="text" placeholder="QR name" disabled={isUpdateForm} onInput={handleNameBlur} />
          </Form.Item>
          <Form.Item name="store" label="Store">
            <StoreSelector
              placeholder="Search by name / code"
              onChange={handleStoreChange}
              onClear={handleStoreClear}
              disabled={qrModelLoading}
            />
          </Form.Item>
          <Form.Item name="menuItem" label="Menu item" rules={[formRules.required]}>
            <MenuItemSelector
              placeholder="Menu Item"
              filter={{
                ...(selectedStoreId && { store: selectedStoreId }),
                isInMenu: true,
              }}
              onChange={handleMenuItemChange}
              disabled={qrModelLoading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>{!!qrName && <QRCodeBlock qrName={qrName} />}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="flex-noWrap mt-7" justify="space-between" align="middle">
        <Row className="flex-noWrap">
          <Button htmlType="submit" type="primary" title="Save" disabled={qrModelLoading} className="mr-5">
            Save
          </Button>

          <Link to={`${ERoutesPrivate.QR}`}>
            <Button title="Cancel" disabled={qrModelLoading}>
              Cancel
            </Button>
          </Link>
        </Row>
        {isUpdateForm && (
          <Button onClick={deleteQR} disabled={qrModelLoading} danger>
            Delete QR
          </Button>
        )}
      </Row>
    </Form>
  );
};
