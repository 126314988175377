import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '@axmit/error-helper';
import { AppDispatch } from 'app/store';
import { orderTransport } from 'entities/Order/Order.transport';
import { IOrderCollection, IOrderCollectionFilter } from 'entities/Order/Order.models';

export interface IState {
  orderCollectionLoading: boolean;
  orderCollection: IOrderCollection | null;
  orderCollectionError: IError | null;
  orderCollectionParams: IOrderCollectionFilter | null;
}

const initialState: IState = {
  orderCollectionLoading: false,
  orderCollection: null,
  orderCollectionError: null,
  orderCollectionParams: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderCollectionLoading(state, action: PayloadAction<boolean>) {
      state.orderCollectionLoading = action.payload;
    },
    setOrderCollection(state, action: PayloadAction<IOrderCollection | null>) {
      state.orderCollection = action.payload;
    },
    setOrderCollectionError(state, action: PayloadAction<IError | null>) {
      state.orderCollectionError = action.payload;
    },
    setOrderCollectionParams(state, action: PayloadAction<IOrderCollectionFilter | null>) {
      state.orderCollectionParams = action.payload;
    },
  },
});

export const { setOrderCollectionLoading, setOrderCollection, setOrderCollectionError, setOrderCollectionParams } =
  orderSlice.actions;
export default orderSlice.reducer;

export const getOrderCollection = (filter?: IOrderCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setOrderCollectionLoading(true));

    try {
      const orderCollection = await orderTransport.getCollection(filter);

      dispatch(setOrderCollection(orderCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderCollectionError(_error));
    } finally {
      dispatch(setOrderCollectionLoading(false));
    }
  }

  return thunk;
};
