import React, { useEffect } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getStatisticGlobalModel, getStatisticModel, getStatisticSalesOpsModel } from 'app/store/reducers/statistic.reducer';
import { OrderCard } from 'entities/Statistic/components/Card/OrderCard';
import { StatsCard } from 'entities/Statistic/components/Card/StatsCard';

interface IComponentProps {
  isSalesOps?: boolean;
  franchiseeId?: string;
  storeId?: string;
  dateFrom?: string;
  dateTo?: string;
}

export const StatisticPage: React.FC<IComponentProps> = (props) => {
  const dispach = useAppDispatch();
  const { statisticModel, statisticSalesOpsModel, statisticGlobalModel } = useAppSelector((state) => state.statistic);
  const { storeId, franchiseeId, dateFrom, dateTo, isSalesOps } = props;
  const globalData = isSalesOps ? statisticSalesOpsModel : statisticGlobalModel;
  const statisticData = isSalesOps ? globalData : storeId ? { ...statisticModel } : { ...globalData };

  useEffect(() => {
    if (isSalesOps) {
      dispach(getStatisticSalesOpsModel({ storeId, franchiseeId, dateFrom, dateTo }));
    } else {
      storeId
        ? dispach(getStatisticModel({ id: storeId, dateFrom, dateTo }))
        : dispach(getStatisticGlobalModel({ dateFrom, dateTo }));
    }
  }, [storeId, franchiseeId, dateFrom, dateTo]);

  return statisticData ? (
    <>
      <StatsCard item={statisticData} />
      <OrderCard item={statisticData} />
    </>
  ) : (
    <LoadingSpin />
  );
};
