import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { getUserCollection, setUserCollection } from 'app/store/reducers/user.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { EUserRole, IUserCollection, IUserCollectionFilter, IUserModel, roleDict } from 'entities/User/User.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: IUserCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const UserTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { userCollection, userCollectionLoading } = useAppSelector((state) => state.user);
  const [mappedCollection, setMappedCollection] = useState<IUserCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IUserCollectionFilter = { ...params };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }

    if (filter?.roles?.length) {
      filterParams.roles = filter?.roles;
    } else {
      filterParams.roles = [EUserRole.Franchisor, EUserRole.Franchisee, EUserRole.SalesOps];
    }

    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }

    dispatch(getUserCollection(filterParams));
  };

  const getCollection = (userCollection: IUserCollection | null) => {
    if (userCollection?.data) {
      const mappedData: IUserModel[] = userCollection.data.map((item) => {
        return { ...item, name: `${item.firstName || ''} ${item.lastName || ''}`, mappedRole: roleDict[item.role] };
      });

      return {
        ...userCollection,
        data: mappedData,
      };
    } else {
      return userCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IUserCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      roles: undefined,
      statuses: undefined,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IUserModel) => {
    history.push(`${ERoutesPrivate.Users}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setUserCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(userCollection));
  }, [userCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={userCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const UserTable = withRouter(UserTableComponent);
