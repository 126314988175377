import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import {
  EPromoSuccessMessage,
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoCreateData,
  IPromoModel,
  IPromoParamsModel,
  IPromoUpdateData,
} from 'entities/Promo/Promo.models';
import { promoTransport } from 'entities/Promo/Promo.transport';

export interface IState {
  promoModelLoading: boolean;
  promoModel: IPromoModel | null;
  promoModelError: IError | null;
  promoModelParams: IPromoParamsModel | IPromoCreateData | IPromoUpdateData | null;
  promoCollectionLoading: boolean;
  promoCollection: IPromoCollection | null;
  promoCollectionError: IError | null;
  promoCollectionParams: IPromoCollectionFilter | null;
}

const initialState: IState = {
  promoModelLoading: false,
  promoModel: null,
  promoModelError: null,
  promoModelParams: null,
  promoCollectionLoading: false,
  promoCollection: null,
  promoCollectionError: null,
  promoCollectionParams: null,
};

export const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    setPromoModelLoading(state, action: PayloadAction<boolean>) {
      state.promoModelLoading = action.payload;
    },
    setPromoModel(state, action: PayloadAction<IPromoModel | null>) {
      state.promoModel = action.payload;
    },
    setPromoModelError(state, action: PayloadAction<IError | null>) {
      state.promoModelError = action.payload;
    },
    setPromoModelParams(state, action: PayloadAction<IPromoParamsModel | IPromoCreateData | IPromoUpdateData | null>) {
      state.promoModelParams = action.payload;
    },
    setPromoCollectionLoading(state, action: PayloadAction<boolean>) {
      state.promoCollectionLoading = action.payload;
    },
    setPromoCollection(state, action: PayloadAction<IPromoCollection | null>) {
      state.promoCollection = action.payload;
    },
    setPromoCollectionError(state, action: PayloadAction<IError | null>) {
      state.promoCollectionError = action.payload;
    },
    setPromoCollectionParams(state, action: PayloadAction<IPromoCollectionFilter | null>) {
      state.promoCollectionParams = action.payload;
    },
  },
});

export const {
  setPromoModelLoading,
  setPromoModel,
  setPromoModelError,
  setPromoModelParams,
  setPromoCollectionLoading,
  setPromoCollection,
  setPromoCollectionError,
  setPromoCollectionParams,
} = promoSlice.actions;
export default promoSlice.reducer;

export const getPromoModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoModelLoading(true));

    try {
      const promoModel = await promoTransport.get(id);

      dispatch(setPromoModel(promoModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoModelError(_error));
    } finally {
      dispatch(setPromoModelLoading(false));
    }
  }

  return thunk;
};

export const addPromoModel = (params: IPromoCreateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoModelLoading(true));
    dispatch(setPromoModelParams(params));

    try {
      const promoModel = await promoTransport.add(params);

      dispatch(setPromoModel(promoModel));

      message.success(EPromoSuccessMessage.Create);
      dispatch(setPromoModelParams(null));
      history.push(ERoutesPrivate.Promo);
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoModelError(_error));
    } finally {
      dispatch(setPromoModelLoading(false));
    }
  }

  return thunk;
};

export const updatePromoModel = (params: IPromoUpdateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoModelLoading(true));
    dispatch(setPromoModelParams(params));

    try {
      const promoModel = await promoTransport.update(params);

      dispatch(setPromoModel(promoModel));

      message.success(EPromoSuccessMessage.Edit);
      dispatch(setPromoModelParams(null));
      history.push(ERoutesPrivate.Promo);
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoModelError(_error));
    } finally {
      dispatch(setPromoModelLoading(false));
    }
  }

  return thunk;
};

export const deletePromoModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoModelLoading(true));

    try {
      await promoTransport.delete(id);

      dispatch(setPromoModel(null));

      message.success(EPromoSuccessMessage.Delete);
      history.push(ERoutesPrivate.Promo);
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoModelError(_error));
    } finally {
      dispatch(setPromoModelLoading(false));
    }
  }

  return thunk;
};

export const getPromoCollection = (filter?: IPromoCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoCollectionLoading(true));

    try {
      const promoCollection = await promoTransport.getCollection(filter);

      dispatch(setPromoCollection(promoCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoCollectionError(_error));
    } finally {
      dispatch(setPromoCollectionLoading(false));
    }
  }

  return thunk;
};
