// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import { objectToQuery } from 'common/helpers/filters.helper';
// import {
//   IStatisticCollection,
//   IStatisticCollectionFilter,
//   IStatisticGlobalParams,
//   IStatisticModel,
//   IStatisticGlobalModel,
//   IStatisticParams,
//   IStatisticCSV,
//   IStatisticSalesOpsParams
// } from 'entities/Statistic/Statistic.models';

// const basePath = '/statistic/stores';

// class StatisticTransport extends BaseHttpTransport<
//   AxiosStatic,
//   IStatisticModel,
//   IStatisticCollection,
//   void,
//   void,
//   IStatisticCollectionFilter
// > {
//   public getModel = (params: IStatisticParams): Promise<IStatisticModel> =>
//     axios.get(`${basePath}/${params.id}${objectToQuery(params)}`);
//   public getGlobalModel = (params: IStatisticGlobalParams): Promise<IStatisticGlobalModel> =>
//     axios.get(`${basePath}/global${objectToQuery(params)}`);
//   public getSalesOpsModel = (params: IStatisticSalesOpsParams): Promise<IStatisticGlobalModel> =>
//     axios.get(`${basePath}/sales-ops${objectToQuery(params)}`);
//   public getStatisticCSV = (): Promise<IStatisticCSV> => {
//     return axios.get(`/statistic/csv/download`, { responseType: 'blob' });
//   };
// }

// export const statisticTransport = new StatisticTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticGlobalParams,
  IStatisticModel,
  IStatisticGlobalModel,
  IStatisticParams,
  IStatisticCSV,
  IStatisticSalesOpsParams,
} from 'entities/Statistic/Statistic.models';

const basePath = '/statistic/stores';

export const statisticTransport = {
  getModel: (params: IStatisticParams): Promise<IStatisticModel> => axios.get(`${basePath}/${params.id}${objectToQuery(params)}`),
  getGlobalModel: (params: IStatisticGlobalParams): Promise<IStatisticGlobalModel> =>
    axios.get(`${basePath}/global${objectToQuery(params)}`),
  getSalesOpsModel: (params: IStatisticSalesOpsParams): Promise<IStatisticGlobalModel> =>
    axios.get(`${basePath}/sales-ops${objectToQuery(params)}`),
  getCollection: (filter?: IStatisticCollectionFilter): Promise<IStatisticCollection> =>
    axios.get(`${basePath}${objectToQuery(filter)}`),
  getStatisticCSV: (): Promise<IStatisticCSV> => axios.get(`/statistic/csv/download`, { responseType: 'blob' }),
};
