import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import { IQRCollection, IQRCollectionFilter, IQRCreateData, IQRModel, IQRParams, EQRSuccessMessage } from 'entities/QR/QR.models';
import { qrTransport } from 'entities/QR/QR.transport';

export interface IState {
  qrModelLoading: boolean;
  qrModel: IQRModel | null;
  qrModelError: IError | null;
  qrModelParams: IQRParams | null;
  qrCollectionLoading: boolean;
  qrCollection: IQRCollection | null;
  qrCollectionError: IError | null;
  qrCollectionParams: IQRCollectionFilter | null;
}

const initialState: IState = {
  qrModelLoading: false,
  qrModel: null,
  qrModelError: null,
  qrModelParams: null,
  qrCollectionLoading: false,
  qrCollection: null,
  qrCollectionError: null,
  qrCollectionParams: null,
};

export const qrSlice = createSlice({
  name: 'qr',
  initialState,
  reducers: {
    setQrModelLoading(state, action: PayloadAction<boolean>) {
      state.qrModelLoading = action.payload;
    },
    setQrModel(state, action: PayloadAction<IQRModel | null>) {
      state.qrModel = action.payload;
    },
    setQrModelError(state, action: PayloadAction<IError | null>) {
      state.qrModelError = action.payload;
    },
    setQrModelParams(state, action: PayloadAction<IQRParams | null>) {
      state.qrModelParams = action.payload;
    },
    setQrCollectionLoading(state, action: PayloadAction<boolean>) {
      state.qrCollectionLoading = action.payload;
    },
    setQrCollection(state, action: PayloadAction<IQRCollection | null>) {
      state.qrCollection = action.payload;
    },
    setQrCollectionError(state, action: PayloadAction<IError | null>) {
      state.qrCollectionError = action.payload;
    },
    setQrCollectionParams(state, action: PayloadAction<IQRCollectionFilter | null>) {
      state.qrCollectionParams = action.payload;
    },
  },
});

export const {
  setQrModelLoading,
  setQrModel,
  setQrModelError,
  setQrModelParams,
  setQrCollectionLoading,
  setQrCollection,
  setQrCollectionError,
  setQrCollectionParams,
} = qrSlice.actions;
export default qrSlice.reducer;

export const getQrModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQrModelLoading(true));

    try {
      const qrModel = await qrTransport.get(id);

      dispatch(setQrModel(qrModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setQrModelError(_error));
    } finally {
      dispatch(setQrModelLoading(false));
    }
  }

  return thunk;
};

export const addQrModel = (params: IQRCreateData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQrModelLoading(true));

    try {
      const qrModel = await qrTransport.add(params);

      dispatch(setQrModel(qrModel));

      message.success(EQRSuccessMessage.Create);
      history.push(ERoutesPrivate.QR);
    } catch (error) {
      const _error = error as IError;

      dispatch(setQrModelError(_error));
    } finally {
      dispatch(setQrModelLoading(false));
    }
  }

  return thunk;
};

export const updateQrModel = (params: IQRParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQrModelLoading(true));

    try {
      const qrModel = await qrTransport.update(params);

      dispatch(setQrModel(qrModel));

      message.success(EQRSuccessMessage.Edit);
      history.push(ERoutesPrivate.QR);
    } catch (error) {
      const _error = error as IError;

      dispatch(setQrModelError(_error));
    } finally {
      dispatch(setQrModelLoading(false));
    }
  }

  return thunk;
};

export const deleteQrModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQrModelLoading(true));

    try {
      await qrTransport.delete(id);

      dispatch(setQrModel(null));

      message.success(EQRSuccessMessage.Delete);
      history.push(ERoutesPrivate.QR);
    } catch (error) {
      const _error = error as IError;

      dispatch(setQrModelError(_error));
    } finally {
      dispatch(setQrModelLoading(false));
    }
  }

  return thunk;
};

export const getQrCollection = (filter?: IQRCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQrCollectionLoading(true));

    try {
      const qrCollection = await qrTransport.getCollection(filter);

      dispatch(setQrCollection(qrCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setQrCollectionError(_error));
    } finally {
      dispatch(setQrCollectionLoading(false));
    }
  }

  return thunk;
};
