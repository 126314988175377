import React from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useAppSelector } from 'app/store/store.hooks';
import { EUserRole } from 'entities/User/User.models';

interface IComponentProps {
  franchisorRoutes: React.ReactElement;
  franchiseeRoutes: React.ReactElement;
  salesOpsRoutes: React.ReactElement;
}
export const RoleCheckLayout: React.FC<IComponentProps> = ({ franchisorRoutes, franchiseeRoutes, salesOpsRoutes }) => {
  const { authUser, authUserLoading } = useAppSelector((state) => state.auth);

  const getRoutesByRole = () => {
    switch (authUser?.role) {
      case EUserRole.Franchisee:
        return franchiseeRoutes;
      case EUserRole.SalesOps:
        return salesOpsRoutes;
      case EUserRole.Franchisor:
      default:
        return franchisorRoutes;
    }
  };

  return authUserLoading ? <LoadingSpin /> : getRoutesByRole();
};
