import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addStoreModel, getByShortIdStoreModel, setStoreModel, updateStoreModel } from 'app/store/reducers/store.reducer';
import { IStoreCreateData, IStoreUpdateData } from 'entities/Store/Store.models';
import { StoreForm } from 'entities/Store/components/Form/StoreForm';
import { StoreFormSkeleton } from 'entities/Store/components/Skeleton/StoreFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams>;

export const StorePage: React.FC<AllProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { storeModel, storeModelLoading } = useAppSelector((state) => state.store);
  const { params } = match;
  const { id } = params;
  const storeName = storeModel?.storeName;
  const isEdit = !!id;
  const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

  const updateStore = (data: IStoreUpdateData | IStoreCreateData) => {
    const id = storeModel?.id;

    if (id) {
      dispatch(updateStoreModel({ path: { id }, data }));
    }
  };

  const createStore = (data: IStoreCreateData) => {
    dispatch(addStoreModel({ data, path: { id: '' } }));
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getByShortIdStoreModel(id));
    }

    return () => dispatch(setStoreModel(null));
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={16}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Stores}>Store list</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !storeModelLoading ? (
                storeName
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Create new store'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!storeModelLoading || !isEdit ? (
          <StoreForm typeForm={typeForm} createStore={createStore} updateStore={updateStore} />
        ) : (
          <StoreFormSkeleton typeForm={typeForm} />
        )}
      </Col>
    </CommonLayout>
  );
};
