import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import { ECommonErrorCode, EErrorStatus } from 'common/models/requestModels';
import { ERoutesCommon } from 'common/models/routesModel';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { history } from 'app/store';
import { clearAuth } from 'app/store/reducers/auth.reducer';

export const errorWatcher = (error: IError | null) => {
  if (!error) {
    return;
  }

  const errorStatus = error.status;

  switch (errorStatus) {
    case EErrorStatus.Unauthorized:
      clearAuth();

      break;
    case EErrorStatus.Forbidden:
      if (error.data?.code === 'error.userInvalidCredentials') {
        message.error(EAuthErrorMessage.InvalidCreds);
      }

      if (error.data?.code === 'error.userBlockedException') {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      break;
    case EErrorStatus.NotConfirmedEmail:
      message.warning('Please check your email and confirm account!');

      break;
    case EErrorStatus.InternalServerError:
      message.warning('Something went wrong');

      break;
    case EErrorStatus.Validation:
      break;
    case EErrorStatus.PreconditionFailed:
      if (error.data.code === ECommonErrorCode.ReleaseVersionNotSupported) {
        message.warning('Something went wrong, reload page and try again');
      }

      if (error.data.code === ECommonErrorCode.ApiIsUnderMaintenance) {
        const { pathname: currentPath } = window.location;
        currentPath !== ERoutesCommon.TechnicalWork && history.push(ERoutesCommon.TechnicalWork);
      }

      break;
    default:
      break;
  }

  console.log('ERROR', error);
};

// export function* errorWatcher() {
//   yield takeEvery('*', function* logger(action: any) {
//     if (action.type.match('FAIL')) {
//       const status = action.payload && action.payload.status;

//       switch (status) {
//         case EErrorStatus.Validation:
//         case EErrorStatus.Forbidden:
//         case EErrorStatus.NotFound:
//           break;
//         case EErrorStatus.Unauthorized:
//           yield clearAuth();
//           break;
//         case EErrorStatus.NotConfirmedEmail:
//           message.warning('Please check your email and confirm account!');
//           break;
//         case EErrorStatus.InternalServerError:
//           break;
//         case EErrorStatus.PreconditionFailed:
//           if (action?.payload?.data?.code === ECommonErrorCode.ReleaseVersionNotSupported) {
//             message.warning('Something went wrong, reload page and try again');
//           }
//           if (action?.payload?.data?.code === ECommonErrorCode.ApiIsUnderMaintenance) {
//             const { pathname: currentPath } = window.location;
//             yield currentPath !== ERoutesCommon.TechnicalWork && put(replace(ERoutesCommon.TechnicalWork));
//           }
//           break;
//         default:
//           break;
//       }
//       console.log('ERROR', action.payload);
//     }
//   });
// }
