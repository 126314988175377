import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { store } from 'app/store';
import { getCustomerCollection, setCustomerCollection } from 'app/store/reducers/customer.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { ICustomerCollection, ICustomerCollectionFilter, ICustomerModel } from 'entities/Customer/Customer.models';
interface IComponentProps {
  config: ColumnsType<any>;
  filter: ICustomerCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const CustomerTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { customerCollection, customerCollectionLoading } = useAppSelector((state) => state.customer);
  const [mappedCollection, setMappedCollection] = useState<ICustomerCollection | null>(null);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: ICustomerCollectionFilter = { ...params };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }

    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }

    store.dispatch(getCustomerCollection(filterParams));
  };

  const getCollection = (customerCollection: ICustomerCollection | null) => {
    if (customerCollection?.data) {
      const mappedData: ICustomerModel[] = customerCollection.data.map((item) => {
        return {
          ...item,
          id: item.userId,
          birthDate: item.birthDate ? dateFormatter(item.birthDate, DATE_FORMAT) : '',
          name: nameBuilder(item?.firstName, item?.lastName),
        };
      });

      return {
        ...customerCollection,
        data: mappedData,
      };
    } else {
      return customerCollection;
    }
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ICustomerCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      statuses: undefined,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: ICustomerModel) => {
    history.push(`${ERoutesPrivate.Customers}/${item.userId}`);
  };

  const clearCollection = () => {
    return dispatch(setCustomerCollection(null));
  };

  useEffect(() => {
    setMappedCollection(getCollection(customerCollection));
  }, [customerCollection]);

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={mappedCollection}
      loading={customerCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const CustomerTable = withRouter(CustomerTableComponent);
