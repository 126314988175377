import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IStoreCollection, IStoreCollectionFilter, IStoreModel, IStoreParams } from 'entities/Store/Store.models';

const basePath = '/stores';

export const storeTransport = {
  get: (id: string): Promise<IStoreModel> => axios.get(`${basePath}/${id}`),
  getByShortId: (id: string): Promise<IStoreModel> => axios.get(`${basePath}/by-short-id/${id}`),
  update: (params: IStoreParams): Promise<IStoreModel> =>
    axios.put(`${basePath}/${params.path?.id}`, {
      ...params.data,
      franchisee: params.data?.franchisee?.id || null,
      asset: params.data?.asset?.id || null,
    }),
  add: (params: IStoreParams): Promise<IStoreModel> =>
    axios.post(basePath, {
      ...params.data,
      franchisee: params.data?.franchisee?.id || undefined,
      asset: params.data?.asset?.id || undefined,
    }),

  delete: (id: string): Promise<void> => axios.delete(`${basePath}/${id}`),

  getCollection: (filter?: IStoreCollectionFilter): Promise<IStoreCollection> => axios.get(`${basePath}${objectToQuery(filter)}`),
};
