import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton, Tabs, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import backIcon from 'app/assets/icons/backIcon.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getStoreModel } from 'app/store/reducers/store.reducer';
import { EStatisticStoreOrderStatus, EStatisticStoreOrderTabText } from 'entities/Statistic/Statistic.models';
import { OrderList } from 'entities/Order/components/OrderList';

interface IParams {
  id?: string;
}

export const StoreOrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { storeModel, storeModelLoading } = useAppSelector((state) => state.store);
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [orderTab, setOrderTab] = useState<EStatisticStoreOrderTabText>(EStatisticStoreOrderTabText.New);
  const storeName = `${storeModel?.storeName || 'Unknown store'} - Orders`;

  const setStatus = (tabKey: EStatisticStoreOrderTabText): EStatisticStoreOrderStatus[] => {
    switch (tabKey) {
      case EStatisticStoreOrderTabText.New:
        return [EStatisticStoreOrderStatus.New];
      case EStatisticStoreOrderTabText.Preparing:
        return [EStatisticStoreOrderStatus.Preparing];
      case EStatisticStoreOrderTabText.Completed:
        return [EStatisticStoreOrderStatus.Completed];
      case EStatisticStoreOrderTabText.Ready:
        return [EStatisticStoreOrderStatus.Ready];
      case EStatisticStoreOrderTabText.Canceled:
        return [EStatisticStoreOrderStatus.CanceledByCustomer, EStatisticStoreOrderStatus.CanceledByStore];

      default:
        return [EStatisticStoreOrderStatus.New];
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getStoreModel(id));
    }
  }, [id]);

  const setOrderTabKey = (key: any) => setOrderTab(key as EStatisticStoreOrderTabText);

  return (
    <FranchiseeCenterLayout>
      <Col span={24}>
        <Row align="middle" className="flex-noWrap cursor-pointer" onClick={() => history.goBack()}>
          <img className="mr-3" src={backIcon} alt="close menu" />
          {!storeModelLoading ? (
            <Row className="width-full" justify="center">
              <Typography.Paragraph className="m-0 p-0 franchisee__text_title" ellipsis={{ rows: 1 }} title={storeName}>
                {storeName}
              </Typography.Paragraph>
            </Row>
          ) : (
            <Row className="width-full" justify="center">
              <Skeleton.Input active style={{ width: '200px' }} size="small" />
            </Row>
          )}
        </Row>

        <Tabs className="mt-5" activeKey={orderTab} onChange={setOrderTabKey}>
          <Tabs.TabPane tab={EStatisticStoreOrderTabText.New} key={EStatisticStoreOrderTabText.New}>
            {orderTab === EStatisticStoreOrderTabText.New && (
              <OrderList
                filter={{
                  store: id,
                  statuses: setStatus(orderTab),
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EStatisticStoreOrderTabText.Preparing} key={EStatisticStoreOrderTabText.Preparing}>
            {orderTab === EStatisticStoreOrderTabText.Preparing && (
              <OrderList
                filter={{
                  store: id,
                  statuses: setStatus(orderTab),
                }}
                showCreateDate
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EStatisticStoreOrderTabText.Ready} key={EStatisticStoreOrderTabText.Ready}>
            {orderTab === EStatisticStoreOrderTabText.Ready && (
              <OrderList
                filter={{
                  store: id,
                  statuses: setStatus(orderTab),
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EStatisticStoreOrderTabText.Completed} key={EStatisticStoreOrderTabText.Completed}>
            {orderTab === EStatisticStoreOrderTabText.Completed && (
              <OrderList
                filter={{
                  store: id,
                  statuses: setStatus(orderTab),
                }}
                showCreateDate
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EStatisticStoreOrderTabText.Canceled} key={EStatisticStoreOrderTabText.Canceled}>
            {orderTab === EStatisticStoreOrderTabText.Canceled && (
              <OrderList
                filter={{
                  store: id,
                  statuses: setStatus(orderTab),
                }}
                showCreateDate
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </FranchiseeCenterLayout>
  );
};
