import { IError } from '@axmit/error-helper';
import { FieldData } from 'rc-field-form/es/interface';
import { useEffect, useState } from 'react';

export interface IValidationErrorData {
  [fieldName: string]: string[];
}

type TFormMapper = (
  fieldsNames: string[],
  data: Object | null,
  params?: Object | null,
  errors?: IError | null
) => { fields: FieldData[]; setField: (params: FieldData) => void; baseError?: string };

export function mapToFields<T extends { [key: string]: any }>(
  stateData: FieldData[],
  data?: T | null,
  params?: T | null,
  errors?: IValidationErrorData | null
): FieldData[] {
  return stateData.map(({ name, value }) => {
    const flatName: string | number = typeof name === 'string' || typeof name === 'number' ? name : name.join('.');

    const hasParams = params && typeof params === 'object' && flatName in params;
    const paramsValue = params && params[flatName];

    const hasValue = data && typeof data === 'object' && flatName in data;
    const dataValue = data && hasValue ? data[flatName] : value;

    const newValue = hasParams ? paramsValue : dataValue;
    const newErrors = errors ? errors[flatName] : [];

    return { value: newValue, name, errors: newErrors };
  });
}

export const useFormMapper: TFormMapper = function (fieldsNames, data, params, errors) {
  const [fields, setFields] = useState<FieldData[]>(fieldsNames.map((item) => ({ name: item, value: undefined })));

  useEffect(() => {
    setFields((prevFields) => mapToFields(prevFields, data, params, errors?.validation));
  }, [data, params, errors, setFields]);

  const setField = (newField: FieldData) => {
    setFields((prevState) => {
      const stateWithoutNew = prevState.filter(({ name }) => name !== newField.name);

      return stateWithoutNew.concat([newField]);
    });
  };

  return { fields, setField };
};
