// import axios, { AxiosStatic } from 'axios';
// import { BaseHttpTransport } from '@axmit/transport';
// import {
//   IAssetCollection,
//   IAssetCollectionFilter,
//   IAssetCreateData,
//   IAssetModel,
//   IAssetUpdateData
// } from 'entities/Asset/Asset.models';

// const basePath = '/assets';

// class AssetTransport extends BaseHttpTransport<
//   AxiosStatic,
//   IAssetModel,
//   IAssetCollection,
//   IAssetCreateData,
//   IAssetUpdateData,
//   IAssetCollectionFilter
// > {}

// export const assetTransport = new AssetTransport(basePath, axios);

import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IAssetCollection,
  IAssetCollectionFilter,
  IAssetCreateData,
  IAssetModel,
  IAssetUpdateData,
} from 'entities/Asset/Asset.models';

const basePath = '/assets';

export const assetTransport = {
  get: (id: string): Promise<IAssetModel> => axios.get(`${basePath}/${id}`),
  add: (params: IAssetCreateData): Promise<IAssetModel> => axios.post(`${basePath}`, params),
  update: ({ id, ...rest }: IAssetUpdateData): Promise<IAssetModel> => axios.put(`${basePath}/${id}`, rest),
  delete: (id: string): Promise<void> => axios.delete(`${basePath}/${id}`),
  getCollection: (filter?: IAssetCollectionFilter): Promise<IAssetCollection> => axios.get(`${basePath}${objectToQuery(filter)}`),
};
