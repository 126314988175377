import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { RouteComponentProps } from 'react-router';
import moment, { Moment } from 'moment';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { rangeConfig } from 'common/helpers/date.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EBaseOrderDirection } from 'common/models/requestModels';
import CustomDatePicker from 'common/components/DateTime/CustomDatePicker';
import backIcon from 'app/assets/icons/backIcon.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getStoreModel, setStoreModel } from 'app/store/reducers/store.reducer';
import { StoreFeedbackList } from 'entities/Rating/components/Franchisee/StoreFeedbackList';
import { StoreFeedbackOrderSelector } from 'entities/Rating/components/Franchisee/StoreFeedbackOrderSelector';

interface IParams {
  id?: string;
}

interface IFilter {
  order?: EBaseOrderDirection;
  dateFrom?: string;
  dateTo?: string;
  isEnable?: boolean;
}

interface IComponentProps {
  isSalesOps?: boolean;
  filter?: IFilter;
}

type AllProps = RouteComponentProps<IParams> & IComponentProps;

export const StoreFeedbackPage: React.FC<AllProps> = ({ history, match, isSalesOps }) => {
  const dispatch = useAppDispatch();
  const { storeModel } = useAppSelector((state) => state.store);
  const [filter, setFilter] = useState<IFilter>({
    ...queryToObject<IFilter>({
      order: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      isEnable: isSalesOps,
    }),
  });
  const { params } = match;
  const { id } = params;
  const storeName = storeModel?.storeName || 'Unknown store';
  const { order, dateFrom, dateTo } = filter;
  const defaultDateValue: [Moment, Moment] | undefined = dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : undefined;
  const storeNameValue = `${storeName} - Feedback`;

  const onChangeSelector = (orderValue?: EBaseOrderDirection) => {
    const order = orderValue ? orderValue : undefined;

    setNewFilter({ order });
  };

  const setNewFilter = (partialFilter: Partial<IFilter>) => {
    const newFilter = { ...filter, ...partialFilter };

    history.replace({ search: objectToQuery(newFilter) });
    setFilter(newFilter);
  };

  const onChangeDate = (value: any[] | null) => {
    if (value?.length) {
      const dateFrom = moment(value[0]).format();
      const dateTo = moment(value[1]).endOf('day').format();

      setNewFilter({ dateFrom, dateTo });
    } else {
      setNewFilter({ dateFrom: undefined, dateTo: undefined });
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    if (id) {
      dispatch(getStoreModel(id));
    }

    return () => dispatch(setStoreModel(null));
  }, []);

  return (
    <FranchiseeCenterLayout>
      <Col span={24}>
        <Row align="middle" className="flex-noWrap cursor-pointer" onClick={() => history.goBack()}>
          <img className="mr-3" src={backIcon} alt="close menu" />
          <Row className="width-full" justify="center">
            <Typography.Paragraph className="m-0 p-0 franchisee__text_title" ellipsis={{ rows: 1 }} title={storeNameValue}>
              {storeNameValue}
            </Typography.Paragraph>
          </Row>
        </Row>
        <Col span={24} className="mt-5 mb-5">
          <div className="fs-xxs font-family__default mb-3">Rating</div>
          <StoreFeedbackOrderSelector onChange={onChangeSelector} value={order} />
        </Col>
        <Col span={24} className="mb-7">
          <div className="fs-xxs font-family__default mb-3">Time range</div>
          <CustomDatePicker.RangePicker
            className="width-full franchisee__date-picker"
            defaultValue={defaultDateValue}
            presets={rangeConfig}
            disabledDate={(current) => current && current > moment().endOf('day')}
            getPopupContainer={(triggerNode) => triggerNode as HTMLElement}
            format={DATE_FORMAT}
            onChange={onChangeDate}
            showTime
          />
        </Col>
        <StoreFeedbackList filter={{ ...filter, store: id }} />
      </Col>
    </FranchiseeCenterLayout>
  );
};
