import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { getCategoryCollection, setCategoryCollection } from 'app/store/reducers/category.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { ICategoryCollection, ICategoryCollectionFilter, ICategoryModel } from 'entities/Category/Category.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter?: ICategoryCollection;
}

type AllProps = IComponentProps & RouteComponentProps;

const CategoryTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { categoryCollection, categoryCollectionLoading } = useAppSelector((state) => state.category);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: ICategoryCollectionFilter = {
      ...params,
      orderField: 'name',
      orderDirection: EBaseOrderDirection.ASC,
      isDeleted: false,
    };

    dispatch(getCategoryCollection(filterParams));
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: ICategoryModel) => {
    history.push(`${ERoutesPrivate.Category}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setCategoryCollection(null));
  };

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={categoryCollection}
      loading={categoryCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const CategoryTable = withRouter(CategoryTableComponent);
