import React, { useState } from 'react';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { RouteComponentProps } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { rangeConfig } from 'common/helpers/date.helper';
import CustomDatePicker from 'common/components/DateTime/CustomDatePicker';
import { useAppSelector } from 'app/store/store.hooks';
import { IStoreModel, selectorStoreAllKeyId } from 'entities/Store/Store.models';
import { StoreSelector } from 'entities/Store/components/Selector/StoreSelector';
import { StatisticPage } from 'entities/Statistic/components/StatisticPage';

interface IFilter {
  store?: Partial<IStoreModel>;
  dateFrom?: string;
  dateTo?: string;
}

type AllProps = RouteComponentProps;

export const StatisticFranchiseePage: React.FC<AllProps> = ({ history }) => {
  const { authUser } = useAppSelector((state) => state.auth);
  const [filter, setFilter] = useState<IFilter>(
    queryToObject<IFilter>({
      store: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })
  );

  const onChangeSelector = (item?: IStoreModel) => {
    const isAll = item?.id === selectorStoreAllKeyId;
    const store = item && !isAll ? { id: item?.id, storeName: item?.storeName } : undefined;

    setNewFilter({ store });
  };

  const setNewFilter = (partialFilter: Partial<IFilter>) => {
    history.replace({ search: objectToQuery(filter) });

    setFilter({ ...filter, ...partialFilter });
  };

  const onChangeDate = (value: any[] | null) => {
    if (value?.length) {
      const dateFrom = moment(value[0]).format();
      const dateTo = moment(value[1]).endOf('day').format();

      setFilter({ dateFrom, dateTo });
    } else {
      setFilter({ dateFrom: undefined, dateTo: undefined });
    }
  };

  const franchiseeId = authUser?.id;
  const store = filter.store;
  const dateFrom = filter.dateFrom;
  const dateTo = filter.dateTo;
  const defaultDateValue: [Moment, Moment] | undefined = dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : undefined;

  return (
    <FranchiseeCenterLayout>
      <Row justify="center" align="middle">
        <span className="franchisee__text_title" title="Statistics">
          Statistics
        </span>
      </Row>
      <Col span={24} className="mt-5 mb-5">
        <div className="fs-xxs font-family__default mb-3">Store</div>
        <StoreSelector
          onChange={onChangeSelector}
          value={store}
          franchiseeId={franchiseeId}
          defaultOpt={{ id: selectorStoreAllKeyId }}
          placeholder="Enter store name"
          allowClear={false}
        />
      </Col>
      <Col span={24}>
        <div className="fs-xxs font-family__default mb-3">Time range</div>
        <CustomDatePicker.RangePicker
          className="width-full franchisee__date-picker"
          defaultValue={defaultDateValue}
          presets={rangeConfig}
          disabledDate={(current) => current && current > moment().endOf('day')}
          getPopupContainer={(triggerNode) => triggerNode as HTMLElement}
          format={DATE_FORMAT}
          onChange={onChangeDate}
          showTime
        />
      </Col>
      <StatisticPage dateFrom={dateFrom} dateTo={dateTo} storeId={store?.id} />
    </FranchiseeCenterLayout>
  );
};
