import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { getAssetCollection, setAssetCollection } from 'app/store/reducers/asset.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { IAssetCollectionFilter, IAssetModel } from 'entities/Asset/Asset.models';

interface IComponentProps {
  config: ColumnsType<any>;
  filter?: IAssetCollectionFilter;
}

type AllProps = IComponentProps & RouteComponentProps;

const AssetTableComponent: React.FC<AllProps> = ({ filter, config, history }) => {
  const dispatch = useAppDispatch();
  const { assetCollection, assetCollectionLoading } = useAppSelector((state) => state.asset);

  const loadCollection = (params: IBaseFilterModel) => {
    const filterParams: IAssetCollectionFilter = { ...params, orderField: 'name', orderDirection: EBaseOrderDirection.ASC };

    dispatch(getAssetCollection(filterParams));
  };

  const addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1,
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return history.replace({ search });
  };

  const onRowClick = (item: IAssetModel) => {
    history.push(`${ERoutesPrivate.Asset}/${item.id}`);
  };

  const clearCollection = () => {
    return dispatch(setAssetCollection(null));
  };

  return (
    <TableCommon
      config={config}
      filter={filter}
      collection={assetCollection}
      loading={assetCollectionLoading}
      loadCollection={loadCollection}
      clearCollection={clearCollection}
      onRowClick={onRowClick}
      addQuery={addQuery}
    />
  );
};

export const AssetTable = withRouter(AssetTableComponent);
