import React from 'react';
import { Row, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EUserStatus, EUserStatusText } from 'entities/User/User.models';

interface IComponentProps {
  onChangeStatus: (value?: EUserStatus) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class UserStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EUserStatus) => {
    const { onChangeStatus } = this.props;
    onChangeStatus(value);
  };

  render() {
    const { value } = this.props;
    return (
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <span className="mr-4">Status</span>
        <Select
          allowClear
          className="width-full"
          placeholder="Select status"
          value={value}
          defaultActiveFirstOption={false}
          onChange={(value: EUserStatus) => this.handleChange(value)}
        >
          <Select.Option key={EUserStatus.Active} value={EUserStatus.Active} label={EUserStatusText.Active}>
            {EUserStatusText.Active}
          </Select.Option>
          <Select.Option key={EUserStatus.Blocked} value={EUserStatus.Blocked} label={EUserStatusText.Blocked}>
            {EUserStatusText.Blocked}
          </Select.Option>
          <Select.Option key={EUserStatus.Pending} value={EUserStatus.Pending} label={EUserStatusText.Pending}>
            {EUserStatusText.Pending}
          </Select.Option>
        </Select>
      </Row>
    );
  }
}
