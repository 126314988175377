import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { AppDispatch } from 'app/store';
import {
  ECustomerSuccessMessage,
  ICustomerCollection,
  ICustomerCollectionFilter,
  ICustomerModel,
  ICustomerParamsModel,
  ICustomerUpdateStatusParams,
} from 'entities/Customer/Customer.models';
import { customerTransport } from 'entities/Customer/Customer.transport';

export interface IState {
  customerModelLoading: boolean;
  customerModel: ICustomerModel | null;
  customerModelError: IError | null;
  customerModelParams: ICustomerParamsModel | null;
  customerCollectionLoading: boolean;
  customerCollection: ICustomerCollection | null;
  customerCollectionError: IError | null;
  customerCollectionParams: ICustomerCollectionFilter | null;
}

const initialState: IState = {
  customerModelLoading: false,
  customerModel: null,
  customerModelError: null,
  customerModelParams: null,
  customerCollectionLoading: false,
  customerCollection: null,
  customerCollectionError: null,
  customerCollectionParams: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerModelLoading(state, action: PayloadAction<boolean>) {
      state.customerModelLoading = action.payload;
    },
    setCustomerModel(state, action: PayloadAction<ICustomerModel | null>) {
      state.customerModel = action.payload;
    },
    setCustomerModelError(state, action: PayloadAction<IError | null>) {
      state.customerModelError = action.payload;
    },
    setCustomerModelParams(state, action: PayloadAction<ICustomerParamsModel | null>) {
      state.customerModelParams = action.payload;
    },
    setCustomerCollectionLoading(state, action: PayloadAction<boolean>) {
      state.customerCollectionLoading = action.payload;
    },
    setCustomerCollection(state, action: PayloadAction<ICustomerCollection | null>) {
      state.customerCollection = action.payload;
    },
    setCustomerCollectionError(state, action: PayloadAction<IError | null>) {
      state.customerCollectionError = action.payload;
    },
    setCustomerCollectionParams(state, action: PayloadAction<ICustomerCollectionFilter | null>) {
      state.customerCollectionParams = action.payload;
    },
  },
});

export const {
  setCustomerModelLoading,
  setCustomerModel,
  setCustomerModelError,
  setCustomerModelParams,
  setCustomerCollectionLoading,
  setCustomerCollection,
  setCustomerCollectionError,
  setCustomerCollectionParams,
} = customerSlice.actions;
export default customerSlice.reducer;

export const getCustomerModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.get(id);

      dispatch(setCustomerModel(customerModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const updateCustomerStatus = (params: ICustomerUpdateStatusParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.updateStatus(params);

      dispatch(setCustomerModel(customerModel));

      message.success(ECustomerSuccessMessage.StatusUpdate);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const getCustomerCollection = (filter?: ICustomerCollectionFilter) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerCollectionLoading(true));

    try {
      const customerCollection = await customerTransport.getCollection(filter);

      dispatch(setCustomerCollection(customerCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerCollectionError(_error));
    } finally {
      dispatch(setCustomerCollectionLoading(false));
    }
  }

  return thunk;
};
