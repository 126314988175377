import React from 'react';
import { Button, Row } from 'antd';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { nameBuilder } from 'common/helpers/name.helper';
import { useAppSelector } from 'app/store/store.hooks';
import { ECustomerStatus, ICustomerUpdateStatusParams } from 'entities/Customer/Customer.models';

interface IComponentProps {
  updateCustomer: (params: ICustomerUpdateStatusParams) => void;
}

const CustomerFormComponent: React.FC<IComponentProps> = (props) => {
  const { updateCustomer } = props;
  const { customerModel, customerModelLoading } = useAppSelector((state) => state.customer);
  const customerId = customerModel?.userId;
  const name = nameBuilder(customerModel?.firstName, customerModel?.lastName);
  const email = customerModel?.email || 'Unknown email';
  const phone = customerModel?.phone || 'Unknown number';
  const birthDate = customerModel?.birthDate ? dateFormatter(customerModel?.birthDate, DATE_FORMAT) : 'Unknown birth date';
  const customerStatus = customerModel?.status;
  const submitButtonText = customerStatus === ECustomerStatus.Blocked ? 'Unblock' : 'Block';
  const isPending = customerStatus === ECustomerStatus.Pending;

  const editCustomer = () => {
    const status = customerStatus === ECustomerStatus.Blocked ? ECustomerStatus.Active : ECustomerStatus.Blocked;

    if (customerId) {
      const data: ICustomerUpdateStatusParams = {
        status,
        id: customerId,
      };

      updateCustomer && updateCustomer(data);
    }
  };

  return (
    <>
      <Row className="flex-noWrap mb-6" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Email">
          Name:
        </span>
        <span className="font-family__default fs-xxs">{name}</span>
      </Row>
      <Row className="flex-noWrap mb-6" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Birthdate">
          Birthdate:
        </span>
        <span className="font-family__default fs-xxs">{birthDate}</span>
      </Row>
      <Row className="flex-noWrap mb-6" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Email">
          Email:
        </span>
        <span className="font-family__default fs-xxs">{email}</span>
      </Row>

      <Row className="flex-noWrap mb-6" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Phone">
          Phone:
        </span>
        <span className="font-family__default fs-xxs">{phone}</span>
      </Row>

      {!isPending && (
        <Button
          danger
          disabled={customerModelLoading}
          loading={customerModelLoading}
          title={submitButtonText}
          onClick={editCustomer}
        >
          {submitButtonText}
        </Button>
      )}
    </>
  );
};

export const CustomerForm = CustomerFormComponent;
