import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPromoPeriodText, EPromoPeriod } from 'entities/Promo/Promo.models';

interface IComponentProps {
  onChange?: (value?: EPromoPeriodText) => void;
  value?: EPromoPeriodText;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PromoPeriodSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPromoPeriodText) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, placeholder } = this.props;
    return (
      <Select
        allowClear
        className="width-full"
        placeholder={placeholder}
        value={value}
        defaultActiveFirstOption={false}
        onChange={(value: EPromoPeriodText) => this.handleChange(value)}
      >
        <Select.Option key={EPromoPeriod.specificDate} value={EPromoPeriod.specificDate} label={EPromoPeriodText.specificDate}>
          {EPromoPeriodText.specificDate}
        </Select.Option>
        {/* Temporary commentedd till no needs */}
        {/* <Select.Option key={EPromoPeriod.specificTime} value={EPromoPeriod.specificTime} label={EPromoPeriodText.specificTime}>
          {EPromoPeriodText.specificTime}
        </Select.Option> */}
        <Select.Option
          key={EPromoPeriod.specificDateAndTime}
          value={EPromoPeriod.specificDateAndTime}
          label={EPromoPeriodText.specificDateAndTime}
        >
          {EPromoPeriodText.specificDateAndTime}
        </Select.Option>
      </Select>
    );
  }
}
